export const validateLoginForm = values => {
  const newErrors = {};

  if (!values.loginId) {
    newErrors.loginId = 'Enter your loginId';
  }
  if (!values.password) {
    newErrors.password = 'Enter your password';
  }
  return newErrors;
};

export const validateForgotForm = values => {
  const newErrors = {};

  if (!values.loginId) {
    newErrors.loginId = 'Enter your loginId';
  }
  return newErrors;
};

export const validateResetPasswordForm = (values) => {
  const errors = {};

  if (!values.otp) {
    errors.otp = 'Enter the OTP';
  } else if (values.otp?.length !== 6) {
    errors.otp = 'OTP should be equal to 6 digits';
  }
  if (!values.password?.trim()) {
    errors.password = 'Enter the new password';
  }
  if (!values.cpassword?.trim()) {
    errors.cpassword = 'Enter the confirm password';
  }
  return errors;
};


export const validateTestCodeForm = values => {
  const newErrors = {};

  if (!values.testCode) {
    newErrors.testCode = 'Enter the valid test code';
  }

  return newErrors;
};

export const validateTestLaunchForm = values => {
  const newErrors = {};

  if (!values.testAccessCode) {
    newErrors.testAccessCode = 'Enter the valid code';
  }

  return newErrors;
};
