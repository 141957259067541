import React from 'react';

const Button = React.forwardRef(({ loading, loadingText, ...props }, ref) => (
  <button
    {...props}
    type={props.type}
    className={props.className}
    title={props.title}
    id={props.id}
    disabled={!!props.disabled}
    onClick={props.onClick}
    ref={ref}>
    {loading ? loadingText : props.children}
  </button>
));

export default Button;
