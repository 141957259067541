/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

import Button from '../Button';

const LogoutAlert = ({ onSuccess, onClose }) => {
  return (
    <div className="logout-box">
      <div className="alert-msg">
        <p className="title">{'Are you sure you want to log out?'}</p>
        <p className="description">
          {'Any tests that haven\'t been uploaded will be lost.'}
        </p>
      </div>
      <div className="action-btns">
        <Button
          className="btn btn-primary"
          role="presentation"
          onClick={onSuccess}>
          Yes
        </Button>
        <Button
          className="btn btn-dark"
          role="presentation"
          onClick={onClose}>
          No
        </Button>
      </div>
    </div>
  );
};

LogoutAlert.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LogoutAlert;
