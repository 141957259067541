import React, { useEffect } from 'react';

const CustomModal = ({
  title,
  children,
  onClose,
  footer = true,
  modalId,
  show,
  className,
  header = true,
  centered = false,
}) => {
  const handleKeyPress = e => {
    if (e.keyCode === 27 && onClose) {
      onClose();
      window.removeEventListener('keydown', handleKeyPress, false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, []);

  return (
    <div
      className={`modal fade ${show ? 'show' : ''} ${className || ''}`}
      id={modalId}
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false">
      <div
        className={`modal-dialog ${centered ? 'modal-dialog-centered' : ''}`}>
        <div className="modal-content">
          {header && (
            <div className="modal-header">
              {title && <h5 className="modal-title">{title}</h5>}
              {onClose && (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}></button>
              )}
            </div>
          )}
          <div className="modal-body">{children}</div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
