import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const DynamicTag = ({ tag: Tag, children, ...props }) => {
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });

    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  return <Tag {...props}>{children}</Tag>;
};

DynamicTag.propTypes = {
  tag: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

const Tooltip = ({
  className,
  placement = 'bottom',
  title,
  children,
  elementTag = '',
}) => {
  const [tagType, setTagType] = useState('span');

  useEffect(() => {
    if (elementTag) {
      setTagType(elementTag);
    }
  }, [elementTag]);

  return (
    <DynamicTag
      tag={tagType}
      className={className}
      data-bs-toggle="tooltip"
      data-bs-placement={placement}
      data-bs-title={title}
    >
      {children}
    </DynamicTag>
  );
};

Tooltip.propTypes = {
  className: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  elementTag: PropTypes.string.isRequired
};

export default Tooltip;
