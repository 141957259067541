/* eslint-disable react/no-danger */
import React from 'react';

import PropTypes from 'prop-types';

const AlertBox = props => {
  if (props.show) {
    return (
      <div
        className={`alert-box alert alert-${props.type} fade show`}
        role="alert">
        <div
          role="presentation"
          className={props.className}
          dangerouslySetInnerHTML={{ __html: props.message }}
          onClick={props.onMessageClick}
        />
        {props.onClose && (
          <div>
            <i
              className="far fa-times cursor-pointer"
              role="presentation"
              onClick={props.onClose}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

AlertBox.defaultProps = {
  message: null,
  type: 'info',
  show: false,
  onClose: null,
  className: '',
  onMessageClick: () => {},
};

AlertBox.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  onMessageClick: PropTypes.func,
};

export default AlertBox;
