import React, { useCallback, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderHtmlWithBlobs from './RenderHtmlWithBlob';
import { Mcq, AN, Mca, Essay } from './Types';
import {
  frameTrack,
  handleInputValue,
  handleSelectValue,
} from '../../redux/actions/action-engine';

const QuestionGroups = ({ activeQuestion, activeTab, ...props }) => {
  const questionRef = useRef(null);
  const passageRef = useRef(null);

  useEffect(() => {
    if (questionRef?.current) {
      questionRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    if (passageRef?.current) {
      passageRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [activeQuestion]);

  const renderBody = useCallback(
    question => {
      let body = '';

      switch (question?.ItemType) {
        case 'mcq':
          body = (
            <Mcq
              question={question}
              handleSelectValue={props.handleSelectValue}
            />
          );
          break;

        case 'mca':
          body = (
            <Mca
              question={question}
              handleSelectValue={props.handleSelectValue}
            />
          );
          break;

        case 'an':
          body = (
            <AN
              question={question}
              handleInputValue={props.handleInputValue}
              frameTrack={props.frameTrack}
            />
          );
          break;

        case 'essay':
          body = (
            <Essay
              question={question}
              handleInputValue={props.handleInputValue}
              frameTrack={props.frameTrack}
            />
          );
          break;

        default:
          body = <div>invalid type</div>;
          break;
      }

      return body;
    },
    [activeQuestion.passageId],
  );

  return (
    <div className="question-grp-wrapper">
      {(activeTab === 'passage' || activeTab === 'both') && activeQuestion.isPassageQn
        && (
          <div
            className="qn-passage"
            ref={passageRef}
          >
            <RenderHtmlWithBlobs htmlString={activeQuestion.passage} />
          </div>
        )}
      {(activeTab === 'questions' || activeTab === 'both') && (
        <div
          className={`passage-qns ${!activeQuestion.isPassageQn ? 'single-view' : ''}`}
          ref={questionRef}>
          {activeQuestion.questions.map((question, qIndex) => {
            return (
              <div
                className="qn-item"
                key={question.ItemID}>
                {renderBody(question, qIndex)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

QuestionGroups.propTypes = {
  activeQuestion: PropTypes.object.isRequired,
  handleInputValue: PropTypes.func.isRequired,
  handleSelectValue: PropTypes.func.isRequired,
  frameTrack: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};

const mapStateToProps = ({ engine }) => ({
  activeQuestion: engine.activeQuestion,
});

const mapDispatchToProps = dispatch => ({
  handleInputValue: (e, question, index) =>
    dispatch(handleInputValue(e, question, index)),
  handleSelectValue: (e, question) => dispatch(handleSelectValue(e, question)),
  frameTrack: (e, question) => dispatch(frameTrack(e, question)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionGroups);
