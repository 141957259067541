/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../styles/DataTable.scss';

const DataTable = ({ wrapperStyle, rowKey, ...props }) => {
  const [state, setState] = useState({
    searchText: '',
    pagination: {
      enable: false,
      totalRecords: 0,
      pageNo: 1,
      displayLimit: 20,
      showDisplayLimit: true,
      position: 'both',
      className: '',
      customSortOrder: 'ascending',
    },
    sorting: null,
    searchColumns: [],
  });

  const initSort = () => {
    const sortColumn = props.columns.filter(
      i => i.sorter && i.defaultSortOrder,
    );
    if (sortColumn.length > 0) {
      if (sortColumn.length === 1) {
        setState({
          sorting: {
            id: sortColumn[0].id,
            sortOrder: sortColumn[0].defaultSortOrder || 'ascending',
          },
        });
      } else {
        console.error('Only one column can have \'defaultSortOrder\'.');
      }
    }
  };

  useEffect(() => {
    if (props.pagination) {
      setState(prev => ({
        ...prev,
        pagination: {
          ...props.pagination,
          enable: true,
          totalRecords: props.rowData.length,
        },
      }));
    }

    initSort();
  }, []);

  const searchFilter = (records, searchText) => {
    if (records && records.length) {
      return records.filter(item =>
        props.columns.some(column => {
          const dataFieldType = typeof item[column.dataField];
          let value = null;

          if (dataFieldType === 'number' || dataFieldType === 'string') {
            value = item[column.dataField];
          }

          if (column.cellRender) {
            value = column.cellRender(item[column.dataField], item);
          }
          return String(value)
            .toLowerCase()
            .includes(String(searchText).toLowerCase());
        }),
      );
    }
    return [];
  };

  // Pagination Methods
  const paginationChange = newOptions => {
    setState(state => ({
      pagination: {
        ...state.pagination,
        ...newOptions,
      },
    }));
  };

  const filterPaginationList = records => {
    const { pageNo, displayLimit } = state.pagination;
    return records.slice((pageNo - 1) * displayLimit, pageNo * displayLimit);
  };

  // Sorting Methods
  const handleSort = column => {
    if (column.customSorter) {
      let customSortOrder = 'ascending';
      if (state.customSortOrder === 'ascending') {
        customSortOrder = 'decending';
      }
      column.customSorter(customSortOrder);
      setState({
        customSortOrder,
      });
    } else {
      const sorting = {
        id: column.id,
        sortOrder: 'ascending',
      };
      if (state.sorting) {
        if (state.sorting.sortOrder === 'ascending') {
          sorting.sortOrder = 'descending';
        } else {
          sorting.sortOrder = 'ascending';
        }
      }
      setState({
        sorting,
      });
    }
  };

  const searchByColumnValues = (rowData, searchColumns) => {
    const items = rowData;
    const finalItems = [];
    for (let i = 0; i < searchColumns.length; i += 1) {
      const filteredItems = items.filter(p => {
        if (
          p[searchColumns[i].dataField] &&
          searchColumns[i].value &&
          searchColumns[i].value.trim()
        ) {
          const searchItems = searchColumns[i].value.split(',');
          return (
            searchItems.filter(
              search =>
                search &&
                search.trim() &&
                String(p[searchColumns[i].dataField])
                  .toLowerCase()
                  .includes(String(search).trim().toLowerCase()),
            ).length > 0
          );
        }
        return false;
      });
      finalItems.push(...filteredItems);
    }

    // return filtered items
    if (searchColumns.filter(p => p.value && p.value.trim()).length) {
      return [...new Set(finalItems)];
    }

    return items;
  };

  let tableRowList = useMemo(() => {
    let list = [...props.rowData];

    if (state.sorting) {
      const sortColumn = props.columns.find(
        columnObj => columnObj.id === state.sorting.id,
      );
      if (sortColumn) {
        list = list.sort((a, b) => {
          if (state.sorting.sortOrder === 'ascending') {
            return sortColumn.sorter(a, b);
          }
          return sortColumn.sorter(b, a);
        });
      }
    }

    if (state.searchText) {
      list = searchFilter(list, state.searchText);
    }

    // search by column
    if (state.searchColumns.length) {
      list = searchByColumnValues(list, state.searchColumns);
    }
    return list;
  }, [props.rowData]);

  if (!props.columns.length) {
    return null;
  }

  let paginationComp = null;
  if (state.pagination?.enable) {
    const { displayLimit, pageNo } = state.pagination;
    const totalRecords = tableRowList.length;
    const noOfPages = Math.ceil(totalRecords / displayLimit);
    const renderPaginationPages = () => {
      const nodes = [];
      let start;
      let end;
      const pageCutOff = 5;
      const ceiling = Math.ceil(pageCutOff / 2);
      const floor = Math.floor(pageCutOff / 2);
      if (noOfPages < pageCutOff) {
        start = 1;
        end = noOfPages;
      } else if (pageNo >= 1 && pageNo <= ceiling) {
        start = 1;
        end = pageCutOff;
      } else if (pageNo + floor >= noOfPages) {
        start = noOfPages - pageCutOff;
        end = noOfPages;
      } else {
        start = pageNo - ceiling;
        end = pageNo + floor;
      }
      for (let i = start; i <= end; i += 1) {
        if (pageNo === i) {
          nodes.push(
            <li
              className="page-item active"
              key={i}>
              <span className="page-link">
                {i}
                <span className="sr-only">(current)</span>
              </span>
            </li>,
          );
        } else {
          nodes.push(
            <li
              role="presentation"
              onClick={() =>
                pageNo === i ? null : paginationChange({ pageNo: i })
              }
              className="page-item"
              key={i}>
              <span className="page-link">{i}</span>
            </li>,
          );
        }
      }
      return nodes;
    };

    paginationComp = (
      <nav className="cb-flex-between">
        <p className="pagination-title">{`Showing ${(pageNo - 1) * displayLimit + 1} - ${pageNo * displayLimit > totalRecords ? totalRecords : pageNo * displayLimit} of ${totalRecords}`}</p>
        <ul className="pagination pagination-sm">
          <li
            role="presentation"
            className={`page-item ${totalRecords >= 0 ? (pageNo === 1 ? 'disabled' : '') : 'disabled'}`}
            onClick={() => {
              if (pageNo && pageNo !== 1) {
                paginationChange({ pageNo: pageNo - 1 });
              }
            }}>
            <span className="page-link">
              <i className="fa fa-chevron-left" />
            </span>
          </li>
          {renderPaginationPages()}
          <li
            role="presentation"
            className={`page-item ${totalRecords >= 0 ? (pageNo === noOfPages ? 'disabled' : '') : 'disabled'}`}
            onClick={() => {
              if (pageNo && noOfPages && pageNo !== noOfPages) {
                paginationChange({ pageNo: pageNo + 1 });
              }
            }}>
            <span className="page-link">
              <i className="fa fa-chevron-right" />
            </span>
          </li>
          {state.pagination && state.pagination.showDisplayLimit && (
            <li className="page-item">
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <label
                    className="input-group-text"
                    htmlFor="paginationLimit">
                    Show
                  </label>
                </div>
                <select
                  onChange={e =>
                    paginationChange({
                      displayLimit: e.target.value,
                      pageNo: 1,
                    })
                  }
                  value={displayLimit}
                  className="custom-select"
                  id="paginationLimit">
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={150}>150</option>
                </select>
              </div>
            </li>
          )}
        </ul>
      </nav>
    );

    tableRowList = filterPaginationList(tableRowList);
  }

  return (
    <div
      className={`data-table-wrapper ${props.wrapperClassName}`}
      style={wrapperStyle}>
      <div
        className={`${props.tableResponsive && 'table-responsive'} ${props.heightAuto ? 'h-auto' : ''} ${props.theadSticky ? 'sticky-header' : ''}`}>
        <table className="table">
          {props.columns && (
            <thead className={`data-table-thead ${props.theadClassName}`}>
              <tr style={{ textAlign: props.theadAlignment }}>
                {props.columns
                  .filter(column => column.id)
                  .map(column => {
                    let headerContent = null;
                    if (column.headRender) {
                      headerContent = column.headRender(column.dataField);
                    } else {
                      headerContent = column.name;
                    }
                    return (
                      <th
                        style={{
                          width: `${column.size}%`,
                          textAlign: column.align || undefined,
                        }}
                        key={column.id}
                        className={
                          column.sorter || column.customSorter
                            ? `th-sorted ${state.sorting?.id === column.id ? 'active' : ''}`
                            : ''
                        }
                        onClick={() => {
                          if (column.sorter || column.customSorter) {
                            handleSort(column);
                          }
                        }}>
                        <div className="column-head">
                          <span className="column-name">{headerContent}</span>
                          {(column.sorter || column.customSorter) && (
                            <i className="fas fa-sort" />
                          )}
                        </div>
                      </th>
                    );
                  })}
              </tr>
            </thead>
          )}
          {!props.loading && tableRowList.length > 0 && (
            <tbody className={`data-table-tbody ${props.tbodyClassName}`}>
              {tableRowList.map((row, rowIndex) => (
                <tr key={rowKey && row[rowKey]}>
                  {props.columns
                    .filter(column => column.id)
                    .map(column => {
                      let cellContent = null;
                      if (column.cellRender) {
                        cellContent = column.cellRender(
                          row[column.dataField],
                          row,
                          rowIndex,
                        );
                      } else if (column.isHyperLink) {
                        cellContent = (
                          <p
                            className="link"
                            onClick={() =>
                              column.hyperLinkUrl(
                                row[column.dataField],
                                row,
                                rowIndex,
                              )
                            }
                            role="presentation">
                            {String(row[column.dataField]) || '-'}
                          </p>
                        );
                      } else {
                        cellContent = String(row[column.dataField]) || '-';
                      }
                      return (
                        <td
                          style={
                            column.align ? { textAlign: column.align } : {}
                          }
                          key={column.id}
                          className={`align-middle ${column.isHyperLink ? 'link' : ''}`}>
                          {cellContent}
                        </td>
                      );
                    })}
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {props.loading && (
          <div className="data-table-loading">
            <div
              className="spinner-border text-primary"
              role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      {tableRowList.length > 0 && !props.loading && state.pagination.enable && (
        <div className="table-below">
          {state.pagination.enable &&
            ['bottom', 'both'].includes(state.pagination.position) &&
            paginationComp}
        </div>
      )}
    </div>
  );
};

DataTable.defaultProps = {
  wrapperStyle: {},
  rowData: [],
  noDataPlaceholder: null,
  tableClassName: 'table',
  wrapperClassName: '',
  theadClassName: '',
  tbodyClassName: '',
  loading: false,
  theadAlignment: 'left',
  bordered: false,
  tableResponsive: true,
  pagination: null,
  search: false,
  onSearch: () => {},
  onSubmit: () => {},
  resetForm: null,
  refreshTable: null,
  noDataText: null,
  enableSearch: true,
  moduleName: null,
  menus: {},
  enableColumnFilter: null,
  heightAuto: false,
  tableId: null,
  theadSticky: false,
};

DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  wrapperStyle: PropTypes.object,
  rowData: PropTypes.array,
  rowKey: PropTypes.string.isRequired,
  noDataPlaceholder: PropTypes.func,
  tableClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  theadClassName: PropTypes.string,
  tbodyClassName: PropTypes.string,
  loading: PropTypes.bool,
  theadAlignment: PropTypes.oneOf(['left', 'center', 'right']),
  bordered: PropTypes.bool,
  tableResponsive: PropTypes.bool,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  search: PropTypes.bool,
  onSearch: PropTypes.func,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  refreshTable: PropTypes.func,
  noDataText: PropTypes.string,
  enableSearch: PropTypes.bool,
  moduleName: PropTypes.string,
  menus: PropTypes.object,
  enableColumnFilter: PropTypes.bool,
  heightAuto: PropTypes.bool,
  tableId: PropTypes.string,
  theadSticky: PropTypes.bool,
};

export default connect(
  ({ auth }, ownProps) => ({
    menus:
      auth.menus &&
      auth.menus.filter(p => p.component === ownProps.moduleName)?.[0],
  }),
  null,
)(DataTable);
