import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Timer from '../Timer';
import useNetworkStatus from '../../../hooks/useNetworkStatus';
import { TestEngineRootContext } from '../../../contexts';

const Header = ({
  testJson = {},
  currentTime,
  testCompleted,
  launching,
  error,
  showInstruction,
}) => {
  const isOnline = useNetworkStatus();
  const { setEngineRootParams } = useContext(TestEngineRootContext);

  return (
    <>
      <nav className="header navbar sticky-top">
        {!testCompleted && !launching && !error && (
          <div className="container-fluid">
            <div className="info-section">
              <p className="mb-0 test-info">
                {isOnline ? (
                  <>
                    <span className="online-btn fas fa-wifi mb-1" />
                    <span className="info-text">Online</span>
                  </>
                ) : (
                  <>
                    <span className="offline-btn fas fa-wifi-slash mb-1" />
                    <span className="info-text">Offline</span>
                  </>
                )}
              </p>
            </div>
            {!showInstruction && (
              <>
                <div className="timer-section">
                  <h5 className="mb-0 test-name">{testJson.Name}</h5>
                  <Timer seconds={currentTime} />
                </div>
                <div className="exit-section">
                  <div
                    className="exit-grp"
                    role="presentation"
                    onClick={() =>
                      setEngineRootParams(p => ({ ...p, showQuitModal: true }))
                    }>
                    <span className="quit-btn far fa-power-off mb-1"></span>
                    <p className="info-text">Quit</p>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </nav>
    </>
  );
};

Header.propTypes = {
  testJson: PropTypes.object.isRequired,
  currentTime: PropTypes.number.isRequired,
  testCompleted: PropTypes.bool.isRequired,
  launching: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  showInstruction: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ engine, timer }) => ({
  testJson: engine.testJson,
  currentTime: timer.currentTime,
  testCompleted: engine.testCompleted,
  launching: engine.launching,
  error: engine.error,
  showInstruction: engine.showInstruction,
});

export default connect(mapStateToProps)(Header);
