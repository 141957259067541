import { toast } from 'react-toastify';

import { getAllTests, setDownloadTokens } from './action-test';
import { clearTimer, startTimer } from './action-timer';
import { TESTENGINE_API_BASE_URL } from '../../constants/config';
import { TOAST_TEST_DOWNLOADED, TOAST_TEST_DOWNLOADING } from '../../constants/data';
import { openInNewTab } from '../../constants/url';
import { checkOnline } from '../../hooks/useNetworkStatus';
import * as Cookies from '../../lib/Cookie';
import {
  deleteTestLaunchData,
  dexieEndTest,
  dexieSyncTest,
  getAllTracks,
  getTestLaunchData,
  getTestToken,
  putTracks,
  storeTestLaunchData,
  updateTestById,
} from '../../lib/dexie';
import { downloadUrlAndChangeToBlob } from '../../lib/jsonurlToBlob';
import Service from '../../lib/Service';
import { engineActions as types } from '../actionTypes';

const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    if (x[key]) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
    }
    return rv;
  }, {});

const groupByPassage = (xs, key) =>
  xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

const extractResponses = tracks => {
  const groupedTracks = groupBy(tracks, 'itemId');

  const responses = Object.keys(groupedTracks).map(item => {
    const allTracks = groupedTracks[item];
    const lastTrack = allTracks[allTracks.length - 1];
    return {
      sectionid: Number(lastTrack.sectionId),
      itemId: Number(lastTrack.itemId),
      selectedOptionId: lastTrack.selectedOptionId,
      enteredText: lastTrack.enteredText,
      isCorrect: lastTrack.isCorrect,
      marked: lastTrack.marked,
      timeTaken: lastTrack.timeTaken,
      attempt: lastTrack.attempt,
    };
  });

  return responses;
};

const savetrack = (data, question, timeRemaining = 0) => {
  const d = new Date();
  const datetime = d.toISOString();


  const newid = `${data.companyCode}_${data.studentId}_${data.testId}_${question.QuestionPaperSectionID}_${question.ItemID}_${data.trackNumber}`;

  return {
    _id: newid,
    testId: data.testId,
    studentId: data.studentId,
    companyCode: data.companyCode,
    itemId: question.ItemID,
    sectionId: question.QuestionPaperSectionID,
    sectionIndex: question.qsectionindex,
    sectionName: question.qsectionname?.replace('\t', '') || '',
    questionIndex: question.qindexbysection,
    subjectId: question.SubjectID,
    subjectName: question.SubjectName,
    areaId: question.AreaID,
    areaName: question.AreaName,
    topicId: question.TopicID,
    topicName: question.TopicName,
    difficultyId: question.DifficultyLevel,
    difficultyName: question.Difficulty,
    timeRemaining,
    isSectionCompleted: null,
    selectedOptionId: data.selectedOptionId,
    attempt: data.attempt,
    itemType: question.ItemType,
    marked: data.marked,
    timeTaken: data.timeTaken || 0,
    timeStamp: datetime,
    points: question.Points,
    negativePoints: question.NegativePoints,
    isCorrect: data.isCorrect,
    enteredText: data.enteredText,
    trackNumber: data.trackNumber,
    saved: 0,
    offlineSaved: 0,
  };
};

const frameStudentTracks = (question, selectedValues = {}, state) => {
  const { testId, studentId, companyCode, trackNumber, currentTime, tracks } =
    state;

  /* save response */
  const timeRemaining = currentTime;

  const trackItem = savetrack(
    {
      testId,
      studentId,
      companyCode,
      selectedOptionId: selectedValues?.selectedOptionId,
      attempt: selectedValues?.attempt,
      isCorrect: selectedValues?.isCorrect,
      enteredText: selectedValues?.enteredText,
      timeTaken: selectedValues?.timeTaken,
      marked: selectedValues?.marked,
      trackNumber,
    },
    question,
    timeRemaining,
  );

  const updatedTracks = tracks.slice();
  updatedTracks.push(trackItem);

  const trackData = {
    tracks: updatedTracks,
    trackNumber: trackNumber + 1,
  };

  return trackData;
};

const getQuestionResponses = (studentResponse, question) => {
  let responseList = studentResponse.slice();
  const questionRes = responseList.filter(
    p =>
      p.sectionid === question.QuestionPaperSectionID &&
      p.itemId === question.ItemID,
  );

  let updateObj = {
    sectionid: question.QuestionPaperSectionID,
    itemId: question.ItemID,
    selectedOptionId: null,
    enteredText: null,
    isCorrect: null,
    marked: null,
    attempt: 1,
    eliminators: [],
    unmasks: [],
  };

  if (questionRes.length > 0) {
    updateObj = {
      sectionid: question.QuestionPaperSectionID,
      itemId: question.ItemID,
      selectedOptionId: questionRes[0].selectedOptionId,
      enteredText: questionRes[0].enteredText,
      isCorrect: questionRes[0].isCorrect,
      marked: questionRes[0].marked,
      attempt: questionRes[0].attempt,
      eliminators: questionRes[0].eliminators || [],
      unmasks: questionRes[0].unmasks || [],
    };
  }
  return updateObj;
};

const saveMissingTracks = () => async (dispatch, getState) => {
  const { studentResponse, testId, questions } = getState().engine;
  const tracksResponse = await getAllTracks({ testId });
  const missedTracks = studentResponse.filter(
    s => !tracksResponse.tracks.some(t => s.itemId === t.itemId),
  );

  await Promise.all(
    missedTracks.map(async missed => {
      const question = questions.find(
        p =>
          p.QuestionPaperSectionID === missed.sectionid &&
          p.ItemID === missed.itemId,
      );
      await dispatch(frameTrack(null, question));
    }),
  );
};

export const handleNavigateQn = isNext => async (dispatch, getState) => {
  const { currentPsgPos, questionGrps, totalQuestionGrps } = getState().engine;
  // await dispatch(saveMissingTracks());

  let newPos = currentPsgPos;

  if (isNext) {
    newPos = currentPsgPos + 1;
  } else {
    newPos = currentPsgPos - 1;
  }

  const nextQuestion = questionGrps[newPos];

  dispatch({
    type: types.SET_TEST_DATA,
    payload: {
      activeQuestion: nextQuestion,
      currentPsgPos: newPos,
      isFirstPsg: newPos === 0,
      isLastPsg: newPos === totalQuestionGrps - 1,
    },
  });
};

export const gotoQuestion = qId => (dispatch, getState) => {
  const { questions, questionGrps, totalQuestionGrps } = getState().engine;

  const newPos = questions.findIndex(p => p.ItemID === qId);

  const newQn = questions[newPos];

  const psgNewPos = questionGrps.findIndex(p => {
    if (!p.passageId) {
      return p.qindexbysection === newQn.qindexbysection;
    }
    if (p.passageId == newQn.ItemPassageID) {
      return true;
    }
  });

  const activeQuestion = questionGrps[psgNewPos];

  let payload = {
    currentPsgPos: psgNewPos,
    isFirstPsg: psgNewPos === 0,
    isLastPsg: psgNewPos === totalQuestionGrps - 1,
    activeQuestion,
  };

  dispatch({
    type: types.SET_TEST_DATA,
    payload,
  });
};

export const handleInputValue =
  (e, question, inputIndex = null) =>
    (dispatch, getState) => {
      if (!e.target) {
        return;
      }
      const { value } = e.target;
      const { studentResponse } = getState().engine;
      const { ItemType } = question;

      let selected = getQuestionResponses(studentResponse, question);

      const responseList = studentResponse.slice();

      const responsePos = studentResponse.findIndex(
        s =>
          s.itemId === question.ItemID &&
          s.sectionid === question.QuestionPaperSectionID,
      );

      if (ItemType === 'essay') {
        selected.enteredText = value;
        selected.isCorrect = value ? false : null;
      }

      if (ItemType === 'an') {
        if (inputIndex !== null) {
          let selectedText =
            selected.enteredText?.split('~').filter(i => i) || [];
          const pos = selectedText.findIndex(s => s.startsWith(`${inputIndex}@`));

          if (pos === -1) {
            selectedText.push(`${inputIndex}@${value}`);
          } else {
            if (value) {
              selectedText.splice(pos, 1, `${inputIndex}@${value}`);
            } else {
              selectedText.splice(pos, 1);
            }
          }
          selected.enteredText = selectedText.join('~');
        } else {
          selected.enteredText = value;
        }
        selected.isCorrect = value ? false : null;
      }
      if (responsePos !== -1) {
        responseList.splice(responsePos, 1, selected);
      } else {
        responseList.push(selected);
      }

      dispatch({
        type: types.SAVE_ANSWER,
        payload: {
          studentResponse: responseList,
        },
      });
    };

export const handleSelectValue = (e, question) => (dispatch, getState) => {
  if (!e.target) {
    return;
  }
  const { value, checked } = e.target;
  const { studentResponse } = getState().engine;
  const { ItemType } = question;

  let selected = getQuestionResponses(studentResponse, question);

  const responseList = studentResponse.slice();

  const responsePos = studentResponse.findIndex(
    s =>
      s.itemId === question.ItemID &&
      s.sectionid === question.QuestionPaperSectionID,
  );

  if (ItemType === 'mcq') {
    const selectedAns = question.ItemOptionResponse.find(
      r => r.ItemOptionID === value,
    );

    selected.selectedOptionId = value;
    selected.isCorrect = selectedAns.IsCorrect;
  }

  if (ItemType === 'mca') {
    let prevSelected = selected.enteredText || '';
    let values = [];
    if (prevSelected) {
      values = [...prevSelected.split('~'), value];
    } else {
      values = [value];
    }
    if (!checked) {
      values = values.filter(v => v !== value);
    }

    const isCorrect = values.every(
      v =>
        question.ItemOptionResponse.find(r => r.ItemOptionID === v)?.IsCorrect,
    );
    selected.enteredText = values.join('~');
    selected.isCorrect = isCorrect;
  }

  if (responsePos !== -1) {
    responseList.splice(responsePos, 1, selected);
  } else {
    responseList.push(selected);
  }

  dispatch({
    type: types.SAVE_ANSWER,
    payload: {
      studentResponse: responseList,
    },
  });

  dispatch(frameTrack(e, question));
};

const validateQuestionGroupResponse =
  (value, question) => (dispatch, getState) => {
    const { studentResponse, activeQuestion } = getState().engine;
    const { ItemType } = question;

    if (ItemType === 'an') {
      if (question.QuestionGroupId) {
        const qnGrps = activeQuestion.questions.filter(
          i => i.QuestionGroupId === question.QuestionGroupId,
        );
        const responses = qnGrps.filter(s => {
          const res = studentResponse.find(
            q =>
              q.itemId === s.ItemID && q.sectionid === s.QuestionPaperSectionID,
          );
          if (res && res.enteredText?.toLowerCase() === value?.toLowerCase()) {
            return true;
          }
          return false;
        });
        const isExist = responses.length > 1;
        if (isExist) {
          dispatch(handleInputValue({ target: { value: '' } }, question));
          alert('Please enter different option');
        }
      }
    }
  };

export const frameTrack = (e, question) => async (dispatch, getState) => {
  const { ItemType } = question;

  if (ItemType === 'an' && e) {
    const { value } = e.target;
    await dispatch(validateQuestionGroupResponse(value, question));
  }
  const { currentTime } = getState().timer;

  const {
    studentResponse,
    testId,
    studentId,
    companyCode,
    trackNumber,
    tracks,
    prevTime,
  } = getState().engine;

  let selected = getQuestionResponses(studentResponse, question);
  const timeTaken = prevTime - currentTime;

  const framedData = frameStudentTracks(
    question,
    {
      ...selected,
      timeTaken,
    },
    {
      testId,
      studentId,
      companyCode,
      trackNumber,
      tracks,
      currentTime,
    },
  );

  dispatch({
    type: types.SAVE_ANSWER,
    payload: {
      ...framedData,
      prevTime: currentTime,
    },
  });
};

export const setBookmarkQn = value => async dispatch => {
  dispatch({
    type: types.SAVE_ANSWER,
    payload: { marked: value },
  });
};

const storeEngineAuthCookie = token => {
  Cookies.set('engine_token', token);
};

const getInitTest = async (testId, courseId) => {
  const initTest = await Service.POST({
    name: 'test',
    payload: JSON.stringify({
      groupId: 0,
      testId: testId,
      courseId: courseId,
    }),
  }).then(res => {
    if (res.success) {
      return res.data;
    }
    throw new Error(res.message);
  });
  return initTest;
};

const getEngineToken = async token => {
  const resData = await Service.GET({
    name: 'validate-token',
    queryString: `token=${token}&companyCode=studentportal`,
    testengine: true,
  }).then(res => {
    if (res.success) {
      return res.data;
    }
    throw new Error(res.message);
  });
  return resData;
};

export const validateEngineToken = token => async dispatch => {
  try {
    dispatch({
      type: types.LAUNCH_TEST,
      payload: { launching: true },
    });

    const resData = await getEngineToken(token);

    const urlParams = new URLSearchParams(resData.redirectLink?.split('?')[1]);

    let redirectToken = urlParams.get('token');

    openInNewTab(`/test-engine?token=${redirectToken}`);

    storeEngineAuthCookie(resData.token);

    dispatch({
      type: types.LAUNCH_TEST,
      payload: { launching: false, ...resData },
    });
  } catch (error) {
    dispatch({
      type: types.LAUNCH_TEST,
      payload: { launching: false },
    });
  }
};

const suffleInnerItems = (questions, randNumber) => {
  const tempEvenQuestions = [];
  const tempOddQuestions = [];
  for (let i = 0; i < questions.length; i += 1) {
    if ((i + 1) % randNumber === 0) {
      tempEvenQuestions.push(questions[i]);
    } else {
      tempOddQuestions.push(questions[i]);
    }
  }
  return tempEvenQuestions.concat(tempOddQuestions);
};

const groupByName = (xs, key) =>
  xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

const suffleItemsByPassage = (questions, randNumber) => {
  const tempEvenQuestions = [];
  const tempOddQuestions = [];
  const groupedQuestions = groupByName(questions, 'ItemPassageID');
  const responses = Object.keys(groupedQuestions);
  for (let i = 0; i < responses.length; i += 1) {
    const shuffledQuestions = suffleInnerItems(
      groupedQuestions[responses[i]],
      randNumber,
    );
    if ((i + 1) % randNumber === 0) {
      tempEvenQuestions.push(...shuffledQuestions);
    } else {
      tempOddQuestions.push(...shuffledQuestions);
    }
  }
  return tempEvenQuestions.concat(tempOddQuestions);
};

const randomizeQuestions = (questions, randNo, shuffleQuestionsBy) => {
  const randNumber = Number(randNo);
  if (randNumber > 0 && randNumber <= 6) {
    const questionsAfterShuffle = [];
    const groupedQuestions = groupByName(
      questions,
      shuffleQuestionsBy || 'QuestionPaperSectionID',
    );
    const responses = Object.keys(groupedQuestions);
    for (let i = 0; i < responses.length; i += 1) {
      const shuffledQuestions = suffleItemsByPassage(
        groupedQuestions[responses[i]],
        randNumber,
      );
      questionsAfterShuffle.push(...shuffledQuestions);
    }
    return questionsAfterShuffle;
  }
  return questions;
};

const getSectionsAndQuestions = (
  sectionResponses,
  random,
  shuffleQuestionsBy,
  isShuffleOptions,
) => {
  const sectionResponse = sectionResponses;
  const sections = [];
  const questions = [];
  let qindex = 0;

  for (let i = 0; i < sectionResponse.length; i += 1) {
    const sectionobj = {
      sectionid: sectionResponse[i].QuestionPaperSectionID,
      sectionname: sectionResponse[i].SectionTitle,
      duration: sectionResponse[i].TotalTime,
      totalmarks: sectionResponse[i].TotalMarks,
      totalquestions: sectionResponse[i].TotalQuestions,
      sectionindex: i,
      total: sectionResponse[i].ItemResponse.length,
      isContinuousQuestionNo: sectionResponse[i].IsContinuousQuestionNo || null,
      sectionLimit: sectionResponse[i].SectionLimit || null,
      parentId: sectionResponse[i].ParentId || 0,
      instruction: sectionResponse[i].Instruction || null,
      beforeBreakTime: sectionResponse[i].BeforeBreakTime || 0,
      AfterBreakTime: sectionResponse[i].afterBreakTime || 0,
      isSectionAdaptive: sectionResponse[i].isSectionAdaptive || false,
      minimumMarks: sectionResponse[i].minimumMarks || 0,
      maximumMarks: sectionResponse[i].maximumMarks || 0,
    };

    sections.push(sectionobj);
    sectionResponse[i].ItemResponse = randomizeQuestions(
      sectionResponse[i].ItemResponse,
      random,
      shuffleQuestionsBy,
    );

    // shuffle options here

    // if (isShuffleOptions) {
    //   sectionResponse[i].ItemResponse = shuffleOptions(sectionResponse[i].ItemResponse, random);
    // }

    let ItemResponse = sectionResponse[i].ItemResponse.map(
      (qItem, itemIndex) => {
        let item = {
          ...qItem,
          qindex: qindex,
          qsectionindex: i,
          qsectionname: qItem.SectionTitle,
          qindexbysection: itemIndex,
          calculatorMode: sectionResponse[i].CalculatorMode || null,
          parentId: sectionResponse[i].ParentId || null,
        };

        qindex += 1;

        return item;
      },
    );

    questions.push(...ItemResponse);
  }

  return {
    sections,
    questions,
  };
};

const formatQuestions = ({
  testJson,
  studentId,
  testId,
  companyCode,
  output,
}) => {
  const totalLanguages =
    testJson.Languages !== '' && testJson.Languages !== null
      ? testJson.Languages.split(',')
      : [];

  const isSectionalTimer = !!testJson.IsSectionalTimer;

  let totalTime = output.sections.reduce(
    (sum, p) => sum + Number(p.duration),
    0,
  );

  if (isSectionalTimer) {
    totalTime = Number(output.sections[0].duration);
  }

  const totalTimeInSecs = totalTime * 60;

  const formattedObj = {
    questions: output.questions,
    loading: false,
    error: null,
    studentId,
    testId,
    testname: testJson.Name,
    companyCode,
    totalDuration: totalTimeInSecs,
    prevTime: totalTimeInSecs,
    instruction: testJson.Instruction,
    showInstruction: true,
    totalQuestions: output.questions.length,
    totalSections: output.sections.length,
    response: [],
    tracks: [],
    sections: output.sections,
    languages: totalLanguages,
    languageIndex: 0,
    trackNumber: 1,
    question: { ...output.questions[0] },
    sectionalTimer: isSectionalTimer,
  };
  return formattedObj;
};

export const checkTestCompleted = ({ testId, courseId, token }) => async (dispatch, getState) => {
  const { user } = getState().auth;
  const initTest = await getInitTest(testId, courseId);
  if (initTest.completed) {
    await hardEndTest({
      testId: testId,
      token: token,
      userId: user.id
    });
    toast.error('Test was already completed');
  }

  return initTest;
};

export const launchTest = token => async dispatch => {
  try {
    dispatch({
      type: types.LAUNCH_TEST,
      payload: { launching: true, testJson: {} },
    });

    const isOnline = checkOnline();

    const offlineResponse = await getTestLaunchData({ token });

    if (!offlineResponse.success) {
      throw new Error(offlineResponse.error || 'Failed to launch test');
    }

    let resData = offlineResponse.data;

    const {
      testJson,
      studentTestData,
      tracks,
      testData,
      userData,
      companyData,
      completed,
    } = resData;

    if (isOnline) {
      const checkedTest = await dispatch(checkTestCompleted({
        testId: studentTestData.testId,
        token: token,
        userId: studentTestData.userId,
        courseId: studentTestData.courseId
      }));

      if (checkedTest.completed) {
        dispatch({
          type: types.TEST_COMPLETED,
          payload: { launching: false, testCompleted: true },
        });
        return;
      }
    }

    if (completed) {
      dispatch(
        syncOfflineTracks({
          testId: studentTestData.testId,
          courseId: studentTestData.courseId,
          end: true,
          redirectToken: token
        }),
      );
      dispatch({
        type: types.TEST_COMPLETED,
        payload: { launching: false, testCompleted: completed },
      });
      return;
    }
    const sectionIndex = 0;

    const output = getSectionsAndQuestions(
      testJson.SectionResponse,
      testData.randomizeNo,
      testData.shuffleQuestionsBy,
      testData.isShuffleOptions,
    );

    const section = output.sections[sectionIndex];

    const totalQuestions = output.questions?.length || 0;

    const groupQuestions = groupByPassage(output.questions, 'ItemPassageID');

    const questionGrps = Object.keys(groupQuestions)
      .map((psgId, pIndex) => {
        const qns = groupQuestions[psgId];
        let psdDetails = qns[0];
        if (psgId === 'null') {
          return qns.map((qn, qI) => {
            return {
              grpIndex: pIndex + qI,
              passageId: null,
              passage: qn?.Items,
              qindexbysection: qn.qindexbysection,
              passageItemIndex: null,
              passageTitle: null,
              questions: [qn],
              audioUrl: null,
              isPassageQn: false,
            };
          });
        }
        return {
          grpIndex: pIndex,
          passageId: psgId,
          passage: psdDetails.Passage,
          passageItemIndex: psdDetails.PassageItemIndex,
          passageTitle: psdDetails.PassageTitle,
          questions: groupQuestions[psgId],
          audioUrl: psdDetails.PassageAudioUrl,
          isPassageQn: true,
        };
      })
      .flat(1)
      .sort((a, b) => a.grpIndex - b.grpIndex);

    const totalQuestionGrps = questionGrps.length;

    dispatch({
      type: types.LAUNCH_TEST,
      payload: {
        launching: false,
        activeSection: section,
        sectionIndex,
        totalQuestions,
        sections: output.sections,
        questions: output.questions,
        totalQuestionGrps,
        questionGrps,
        activeQuestion: questionGrps[0],
        ...resData,
      },
    });

    const studentId = studentTestData.userId;
    const testId = studentTestData.testId;
    const companyCode = studentTestData.companyCode;

    let framedData = formatQuestions({
      testJson,
      studentId,
      testId,
      companyCode,
      output,
    });

    framedData.showInstruction = !(
      framedData.instruction == null || framedData.instruction == ''
    );

    framedData.userData = userData;
    framedData.companyData = companyData;

    // for administrator

    framedData.isAdministrator = studentTestData.isAdministrator || 0;

    framedData.testTemplate = testData.testTemplate || null;

    let lastQuestionId = output.questions[0].ItemID;

    if (tracks.length > 0) {
      framedData.studentResponse = extractResponses(tracks);
      framedData.showInstruction = false;
      framedData.token = token;
      framedData.showReport = testData.showReport;
      framedData.totalDuration = tracks[tracks.length - 1].timeRemaining;
      framedData.prevTime = tracks[tracks.length - 1].timeRemaining;
      framedData.trackNumber = tracks[tracks.length - 1].trackNumber + 1;
      lastQuestionId = Number(tracks[tracks.length - 1].itemId);
      dispatch(startTimer(framedData.totalDuration));
    } else {
      framedData.token = token;
      if (!framedData.showInstruction) {
        dispatch(startTimer(framedData.totalDuration));
      }
    }

    dispatch({
      type: types.LAUNCH_TEST,
      payload: {
        launching: false,
        ...resData,
        ...framedData,
      },
    });

    dispatch(gotoQuestion(lastQuestionId));
  } catch (error) {
    dispatch({
      type: types.LAUNCH_TEST,
      payload: {
        launching: false,
        testJson: {},
        error: error.message || 'Something went wrong. Contact administrator',
      },
    });
  }
};

export const startTest = () => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: types.START_TEST,
    payload: {
      showInstruction: false,
    },
  });

  dispatch(startTimer(state.engine.totalDuration));
};

export const endSection = () => (dispatch, getState) => {
  // Functionality Not completed //
  const state = getState();
  const { activeQuestion, sections } = state.engine;
  const nextSectionIndex = activeQuestion.qsectionindex + 1;

  if (nextSectionIndex <= sections.length) {
    const getSection = sections.filter(
      p => p.sectionindex === nextSectionIndex,
    );
    const sectionTime = Number(getSection[0].duration) * 60;
    const updatedobj = {};
    updatedobj.totalDuration = sectionTime;
    updatedobj.prevTime = sectionTime;

    // dispatch({
    //   type: types.SET_TEST_DATA,
    //   payload: {
    //     ...updatedobj,
    //   },
    // });
    // dispatch(updateTimer(updatedobj));
    // dispatch(startTimer(sectionTime));
    // dispatch(goToSection(getSection[0].sectionid));
  }
};

const limitArray = arr => {
  const limitedArr = arr.filter((item, index) => index < 21);
  return limitedArr;
};

const syncsuccess = (oldTracks, newTracks) => {
  const updatedTracks = oldTracks.map(item => {
    let existItem = newTracks.find(p => p.id === item.id);
    if (existItem) {
      return {
        ...item,
        saved: 1,
      };
    }
    return item;
  });

  const updateObj = {
    tracks: updatedTracks,
    synError: null,
  };

  return updateObj;
};

const syncsuccessOffline = (oldTracks, newTracks) => {
  const updatedTracks = oldTracks.map(item => {
    let existItem = newTracks.find(p => p.id === item.id);
    if (existItem) {
      return {
        ...item,
        offlineSaved: 1,
      };
    }
    return item;
  });

  const updateObj = {
    tracks: updatedTracks,
    synError: null,
  };

  return updateObj;
};

export const handleEndTest = () => dispatch => {
  const isOnline = checkOnline();

  if (!isOnline) {
    toast.info(
      'It seems you\'re offline. Sync your test whenever you\'re online',
    );
  }

  dispatch(syncTrack(true));
};

const endTest = (syncToken, reload = true) => async (dispatch, getState) => {
  try {
    const { token } = getState().engine;
    let testToken = token;

    if (!token) {
      testToken = syncToken;
    }

    const payload = {
      token: testToken,
    };

    const isOnline = checkOnline();

    dispatch({
      type: types.END_TEST_START,
      payload: { ending: true },
    });

    const offlineResponse = await dexieEndTest({ token: testToken });

    if (!offlineResponse.success) {
      throw new Error('Test Submit not successful');
    }

    if (isOnline) {
      await Service.POST({
        name: 'end-test',
        payload: payload,
        testengine: true,
      }).then(res => {
        if (res.success) {
          return res.data;
        }
        throw new Error(res.message);
      });

      await deleteTestLaunchData({ token: testToken });
    }

    dispatch(clearTimer());

    dispatch({ type: types.END_TEST_SUCCESS, payload: { ending: false, submitting: false } });

    if (reload) {
      openInNewTab('/');
    } else {
      dispatch(getAllTests());
    }
  } catch (error) {
    dispatch({
      type: types.END_TEST_FAILED,
      payload: {
        ending: false,
        submitting: false,
        error:
          'SUBMIT ERROR: Submit not successful. Please contact administrator!',
      },
    });
  }
};

export const syncTrack = (end = 0, isEvaluationTest) => async (dispatch, getState) => {
  const isOnline = checkOnline();
  const { engine } = getState();
  const tracks = engine.tracks.filter(p =>
    isOnline ? p.saved === 0 : p.offlineSaved === 0,
  );

  let i,
    j,
    chunk = 20;

  const reload = isEvaluationTest ? false : true;

  for (i = 0, j = tracks.length; i < j; i += chunk) {
    const data = {
      track: tracks.slice(i, i + chunk).map(t => ({ ...t, id: t._id })),
    };

    if (tracks.length > 0) {
      await putTracks(tracks, engine.token);

      if (isOnline) {
        await Service.POST({
          name: 'sync-track',
          payload: data,
          testengine: true,
        }).then(async res => {
          if (res?.data?.error) {
            dispatch({
              type: types.SYNC_FAILED,
              error: {
                message:
                  'SYNC ERROR: Responses are not saved. Please contact administrator!',
              },
            });
          } else {
            const syncRes = syncsuccess(engine.tracks, tracks);
            await putTracks(syncRes.tracks, engine.token);
            dispatch({
              type: types.SYNC_SUCCESS,
              payload: { ...syncRes },
            });
            if (end) {
              dispatch(endTest(null, reload));
            }
          }
        });
      } else {
        const syncRes = syncsuccessOffline(engine.tracks, tracks);
        await putTracks(syncRes.tracks, engine.token);
        dispatch({
          type: types.SYNC_SUCCESS,
          payload: { ...syncRes },
        });
        if (end) {
          dispatch(endTest(null, reload));
        }
      }
    } else {
      dispatch({
        type: types.SYNC_NOITEMS,
      });
      if (end) {
        dispatch(endTest(null, reload));
      }
    }
  }

  if (end && tracks.length === 0) {
    dispatch(endTest(null, reload));
  }
};

export const hardEndTest = async ({ token, testId, userId }) => {
  await dexieEndTest({ token });
  await dexieSyncTest({ testId, userId });
  await deleteTestLaunchData({ token });
};

export const handleSyncTest = (record) => async (dispatch, getState) => {
  const isOnline = checkOnline();
  const { user } = getState().auth;

  if (!isOnline) {
    toast.info(
      'It seems you\'re offline. Upload your test when you\'re online',
    );
    return;
  }

  const offlineResponse = await getTestToken({
    testId: record.testId,
    courseId: record.courseId,
    userId: user.id,
  });

  const checkedTest = await dispatch(checkTestCompleted({
    testId: record.testId,
    userId: user.id,
    courseId: record.courseId,
    token: offlineResponse.token
  }));

  if (checkedTest.completed) {
    dispatch({
      type: types.TEST_COMPLETED,
      payload: { launching: false, testCompleted: true },
    });
    dispatch(getAllTests());
    return;
  }

  dispatch(syncOfflineTracks({
    testId: record.testId,
    courseId: record.courseId,
    end: true,
    redirectToken: checkedTest.redirectToken
  }));
};

export const handleUploadAll = () => async (dispatch, getState) => {
  const isOnline = checkOnline();
  const { tests } = getState().test;
  if (!isOnline) {
    toast.info(
      'It seems you\'re offline. Upload your test when you\'re online',
    );
    return;
  }

  const syncTest = tests.some((test) =>
    test.subItems.some((subItem) => subItem.studentTestDetails.isSynced === 0)
  );

  if (!syncTest) {
    toast.info(
      'All your completed tests are already uploaded.',
    );
    return;
  }

  for (const test of tests) {
    if (test.subItems.length) {
      for (const item of test.subItems) {
        if (item.studentTestDetails.isSynced === 0) {
          try {
            await dispatch(handleSyncTest({
              testId: item.id,
              courseId: item.courseId,
              end: true,
            }));
          } catch (e) {
            console.error(`Error syncing test: ${item.id}`, e);
          }
        }
      }
    }
  }
};

export const syncOfflineTracks = ({
  testId,
  courseId,
  end = false,
  redirectToken
}) => async (dispatch, getState) => {
  try {
    const isOnline = checkOnline();
    const { user } = getState().auth;
    if (!isOnline) {
      toast.info(
        'It seems you\'re offline. Upload your test when you\'re online',
      );
      return;
    }

    dispatch({
      type: types.SYNC_RECORDS,
      payload: { syncId: testId, syncing: true },
    });

    const engine = await getAllTracks({ testId, courseId, userId: user.id });

    if (!engine.success) {
      toast.error('Sync Failed');
      throw new Error('Sync Failed');
    }

    const validateTokenRes = await getEngineToken(redirectToken);

    await storeEngineAuthCookie(validateTokenRes.token);

    let unsyncedTracks = engine.tracks.filter(p => p.saved === 0);
    if (unsyncedTracks.length === 0) {
      if (end) {
        await dispatch(endTest(engine.testData.token, false));
        await dexieSyncTest({ testId, courseId, userId: user.id });
        dispatch(getAllTests());
        toast.info('Test already synced');
      }
      dispatch({
        type: types.SYNC_RECORDS,
        payload: { syncId: null, syncing: false },
      });
      return;
    }

    let i,
      j,
      records,
      chunk = 10;

    for (i = 0, j = unsyncedTracks.length; i < j; i += chunk) {
      records = unsyncedTracks.slice(i, i + chunk);

      await Service.POST({
        name: 'sync-track',
        payload: {
          track: records.map(t => ({ ...t, id: t._id })),
        },
        testengine: true,
      }).then(async res => {
        if (res?.data?.error) {
          dispatch({
            type: types.SYNC_FAILED,
            error: {
              message:
                'SYNC ERROR: Responses are not saved. Please contact administrator!',
            },
          });
        } else {
          const syncRes = syncsuccess(engine.tracks, records);
          await putTracks(syncRes.tracks, engine.testData.token);
          dispatch({
            type: types.SYNC_SUCCESS,
            payload: { ...syncRes },
          });
        }
      });
    }

    if (end) {
      await dispatch(endTest(engine.testData.token, false));
      await dexieSyncTest({
        testId: engine.testData.id,
        userId: engine.testData.userId,
      });
    }

    dispatch({
      type: types.SYNC_RECORDS,
      payload: { syncId: '', syncing: false },
    });
  } catch (error) {
    dispatch({
      type: types.SYNC_NOITEMS,
    });
  }
};

export const downloadTestEngineLaunch = ({ testId, courseId }) => async (dispatch, getState) => {
  const { user } = getState().auth;

  toast.info(
    'Test download in progress. Do not refresh or close the page',
    {
      hideProgressBar: true,
      autoClose: false,
      toastId: TOAST_TEST_DOWNLOADING
    },
  );
  try {
    dispatch(setDownloadTokens(testId));
    await updateTestById(testId, user.id, { isDownloading: 1 });

    dispatch({
      type: types.TEST_DOWNLOAD,
      payload: {
        testDownloading: true,
        testDownloadingRef: testId,
        testDownloadError: null,
      },
    });

    const initTest = await getInitTest(testId, courseId);

    const validateTest = await getEngineToken(initTest.redirectToken);

    const urlParams = new URLSearchParams(
      validateTest.redirectLink?.split('?')[1],
    );
    let testToken = urlParams.get('token');

    const response = await fetch(`${TESTENGINE_API_BASE_URL}/test-launch`, {
      method: 'POST',
      headers: {
        'Authorization': validateTest.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: testToken })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    const launchRes = await response.json();

    if (launchRes.success) {
      await downloadUrlAndChangeToBlob(
        JSON.stringify(launchRes.data.testJson),
        testToken,
      );

      await storeTestLaunchData({
        ...launchRes.data,
        token: testToken,
        userId: user.id,
        testId,
      });
    }

    await updateTestById(testId, user.id, { isDownloading: 0 });

    dispatch({
      type: types.TEST_DOWNLOAD,
      payload: { testDownloading: false, testDownloadingRef: null },
    });
    toast.success('Test downloaded.', { toastId: TOAST_TEST_DOWNLOADED });
  } catch (error) {
    await updateTestById(testId, user.id, { isDownloading: 2 });
    dispatch({
      type: types.TEST_DOWNLOAD,
      payload: {
        testDownloading: false,
        testDownloadingRef: null,
        testDownloadError: error.message,
      },
    });
    toast.error('Test download failed');
  } finally {
    dispatch(setDownloadTokens(testId, true));
    dispatch(getAllTests());
  }
};

export const submitEvaluationTest = (test) => async (dispatch, getState) => {
  try {
    const launchData = await getTestLaunchData({ token: test.token });

    const { testJson, testData } = launchData.data;
    const { user } = getState().auth;
    const isOnline = checkOnline();

    dispatch({
      type: types.SUBMIT_EVALUATION,
      payload: { token: test.token, testId: test._id, submitting: true }
    });

    if (isOnline) {
      const checkedTest = await dispatch(checkTestCompleted({
        testId: test._id,
        userId: user.id,
        courseId: test.courseId,
        token: test.token
      }));

      if (checkedTest.completed) {
        dispatch({
          type: types.TEST_COMPLETED,
          payload: { launching: false, testCompleted: true },
        });
        dispatch(getAllTests());
        return;
      }

      const validateTokenRes = await getEngineToken(checkedTest.redirectToken);

      await storeEngineAuthCookie(validateTokenRes.token);
    }

    const output = getSectionsAndQuestions(
      testJson.SectionResponse,
      testData.randomizeNo,
      testData.shuffleQuestionsBy,
      testData.isShuffleOptions,
    );

    const data = {
      testId: test._id,
      studentId: user.id,
      companyCode: user.companyCode,
      currentTime: 0,
      tracks: [],
      trackNumber: 1
    };

    const selectedValues = {
      attempt: true,
      isCorrect: false,
      timeTaken: 0,
    };

    let tracks = [];

    await Promise.all(output.questions.map(async question => {
      let framed = await frameStudentTracks(question, selectedValues, data);
      tracks = [...tracks, ...framed.tracks.map(t => {
        let temp = { ...t };
        delete temp.id;
        return {
          ...temp,
          offlineSaved: 0
        };
      })];
    }));

    dispatch({
      type: types.SUBMIT_EVALUATION,
      payload: { tracks }
    });

    await dispatch(syncTrack(true, true));
    toast.success('Test marked as done');
  } catch (error) {
    dispatch({
      type: types.SUBMIT_EVALUATION,
      payload: { submitting: false }
    });
    toast.error('Submit failed');
  }
};