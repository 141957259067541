import { updateState } from '../../lib/utils';
import { timerActions as types } from '../actionTypes';

const initialState = {
  oveallTime: 0,
  currentTime: 0,
  countDownTimer: 0,
};

const starttimer = (state, action) => {
  const updateObj = { oveallTime: action.seconds, currentTime: action.seconds };
  return updateState(state, updateObj);
};

const settimer = (state, action) =>
  updateState(state, { currentTime: action.seconds });

const stoptimer = (state) => updateState(state, { currentTime: 0 });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.START_TIMER:
      return starttimer(state, action);
    case types.SET_TIMER:
      return settimer(state, action);
    case types.STOP_TIMER:
      return stoptimer(state, action);
    case types.SET_COUNTDOWN_TIMER:
      return updateState(state, { countDownTimer: action.countDownTimer });
    default:
      return state;
  }
};
export default reducer;
