import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { gotoQuestion } from '../../redux/actions/action-engine';
import { Button } from '../domain';

const QuestionPallete = ({
  currentPsgPos,
  testJson,
  totalQuestionGrps,
  studentResponse,
  questionGrps,
  gotoQuestion,
  toggle = false,
}) => {
  return (
    <div className="qn-pallete">
      <div className="dropup-center dropup">
        <Button
          type="button"
          className={`btn btn-pallete ${toggle ? 'dropdown-toggle' : ''}`}
          data-bs-toggle="dropdown"
          id="pallete"
          aria-expanded="false">
          {`Page ${currentPsgPos} of ${totalQuestionGrps}`}
        </Button>
        <div
          className="dropdown-menu pallete-wrapper"
          id="pallete">
          <h5>{testJson.Name}</h5>
          <hr />
          <div className="ans-status">
            <p>
              <span className="fas fa-square me-2 text-secondary" />
              Answered
            </p>
            <p>
              <span className="far fa-square me-2 skipped" />
              Unanswered
            </p>
          </div>
          <hr />
          {questionGrps?.length && (
            <div className="passage-details">
              {questionGrps.map(passage => {
                const selected = passage.questions.every(qItem => {
                  return studentResponse.find(
                    t =>
                      t.sectionid === qItem.QuestionPaperSectionID &&
                      t.itemId === qItem.ItemID,
                  );
                });

                return (
                  <div
                    className="ans-indexes"
                    key={passage.grpIndex}>
                    {passage.passageTitle && (
                      <h6
                        className={`passage-title ${selected && selected?.isCorrect !== null ? 'answered' : ''}`}
                        onClick={() => {
                          if (passage.questions.length > 0) {
                            gotoQuestion(passage.questions[0]?.ItemID);
                          }
                        }}
                        role="presentation">
                        {passage.passageTitle}
                      </h6>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

QuestionPallete.propTypes = {
  testJson: PropTypes.object.isRequired,
  totalQuestionGrps: PropTypes.number.isRequired,
  currentPsgPos: PropTypes.number.isRequired,
  activeSection: PropTypes.object.isRequired,
  studentResponse: PropTypes.array.isRequired,
  gotoQuestion: PropTypes.func.isRequired,
  questionGrps: PropTypes.array.isRequired,
  toggle: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
};

const mapStateToProps = ({ engine }) => ({
  testJson: engine.testJson,
  totalQuestionGrps: engine.totalQuestionGrps,
  currentPsgPos: engine.currentPsgPos + 1,
  activeSection: engine.activeSection,
  studentResponse: engine.studentResponse,
  questionGrps: engine.questionGrps,
});

const mapDispatchToProps = dispatch => ({
  gotoQuestion: id => dispatch(gotoQuestion(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPallete);
