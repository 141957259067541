import React, { Fragment, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderHtmlWithBlobs from '../RenderHtmlWithBlob';

const AN = ({ question, studentResponse, ...props }) => {
  const studentAns = useMemo(
    () =>
      studentResponse.find(
        t =>
          t.sectionid === question.QuestionPaperSectionID &&
          t.itemId === question.ItemID,
      ),
    [question.ItemID, studentResponse],
  );

  // const renderHTMLWithInputs = (htmlString) => {
  //   const parts = htmlString.split('[quizky-text]');
  //   return parts.map((part, index) => (
  //     `${part}${index < parts.length - 1
  //       ? `<input type="text" class="form-control" value="${''}" onchange="props.handleInputChange(event, ${question})" onblur="props.frameTrack(event, ${question})" placeholder="">`
  //       : ''}`
  //   )).join('');
  // };

  const renderQuestion = useCallback(() => {
    let qn = question.Items.replaceAll('[quizky-text]', '');
    return <RenderHtmlWithBlobs htmlString={qn} />;
  }, [question.ItemID]);

  const count = useMemo(() => {
    let word = 'quizky-text';
    const regex = new RegExp(`\\b${word}\\b`, 'gi');
    const matches = question.Items.match(regex);

    return matches ? matches.length : 0;
  }, [question.ItemID]);

  return (
    <div
      className="type-an-wrapper"
      key={question.ItemID}>
      <div className="qn-question"> {renderQuestion()}</div>
      <div className="qn-options">
        {Array(count)
          .fill(null)
          .map((_, index) => {
            let value = studentAns?.enteredText;
            if (count > 1) {
              let enteredText = studentAns?.enteredText?.split('~') || [];
              let values = enteredText[index]?.split('@') || '';
              value = values ? values[1] : '';
            }
            return (
              <Fragment key={index}>
                <input
                  className="form-control"
                  type="text"
                  name={`${question.ItemID}-${index + 1}`}
                  id={`${question.ItemID}-${index + 1}`}
                  onChange={e => {
                    if (count > 1) {
                      props.handleInputValue(e, question, index);
                    } else {
                      props.handleInputValue(e, question);
                    }
                  }}
                  onBlur={e => {
                    props.frameTrack(e, question);
                  }}
                  value={value}
                />
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

AN.propTypes = {
  question: PropTypes.object.isRequired,
  handleInputValue: PropTypes.func.isRequired,
  studentResponse: PropTypes.array.isRequired,
  frameTrack: PropTypes.func.isRequired,
};

const mapStateToProps = ({ engine }) => ({
  studentResponse: engine.studentResponse,
});

export default connect(mapStateToProps)(AN);
