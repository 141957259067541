import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../CustomModal';
import LogoutAlert from './LogoutAlert';
import { connect } from 'react-redux';
import { getFirstCharOfStrings } from '../../../lib/utils';
import useScreenProps from '../../../hooks/useScreenProps';

const Header = ({ user }) => {
  const [showLogoutModal, setLogoutModal] = useState(false);
  const { isMobileView } = useScreenProps();
  const navigate = useNavigate();

  return (
    <nav className="header navbar sticky-top">
      <div className="container-fluid">
        <div className="logo-wrapper">
          {isMobileView && (
            <img className="logo" src="/logo.png" alt="kanan.co" />
          )}
        </div>
        <div className="dropdown">
          <div
            className="menu-icon"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            <p>{getFirstCharOfStrings(user?.studentName)}</p>
          </div>
          <ul className="dropdown-menu dropdown-menu-end mt-2">
            <li>
              <span className="dropdown-item-text py-0">
                {user?.studentName}
              </span>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li
              className="dropdown-item cursor-pointer font-13"
              onClick={() => navigate('/settings')}
              role="presentation">
              <span className="far fa-cog me-2" />
              Settings
            </li>
            <li
              className="dropdown-item cursor-pointer font-13"
              onClick={() => setLogoutModal(true)}
              role="presentation">
              <span className="fas fa-sign-out-alt me-2" />
              Logout
            </li>
          </ul>
        </div>
        <CustomModal
          centered
          onClose={() => setLogoutModal(false)}
          show={showLogoutModal}
          header={false}
          footer={false}>
          <LogoutAlert
            onClose={() => setLogoutModal(false)}
            onSuccess={() => navigate('/logout')}
          />
        </CustomModal>
      </div>
    </nav>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(Header);
