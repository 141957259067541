import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ResetPasswordForm } from '../components';
import {
  resetPassword, forgotPassword
} from '../redux/actions/action-auth';

const Reset = (props) => {
  const navigate = useNavigate();
  const [resendThrottle, setResendThrottle] = useState(60);

  const submit = async (values) => {
    await props.initResetPassword(values);
  };

  const runThrottleTimer = () => {
    const intervalId = setInterval(() => {
      setResendThrottle((prev) => {
        if (prev === 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    runThrottleTimer();
    return () => clearInterval();
  }, []);

  const handleResendOtp = () => {
    setResendThrottle(60);
    runThrottleTimer();
    props.forgotPassword({
      ...props.resetPayload
    });
  };

  let content = null;

  if (props.successMsg) {
    navigate('/');
  } else {
    content = (
      <div>
        <ResetPasswordForm
          onSubmit={submit}
        />
        <span className="d-block mt-3">
          {resendThrottle > 0 && (
            <div className="otp-timer">
              <span>{`If you didn't receive code, resend OTP in ${resendThrottle}`}</span>
            </div>
          )}
          {resendThrottle <= 0 && !props.loading && (
            <div
              className="d-flex gap-2"
              role="presentation"
              onClick={handleResendOtp}
            >
              <span className="text-secondary mr-2">Didn&apos;t receive any code?</span>
              <span className="text-danger ml-2 cursor-pointer">Resend</span>
            </div>
          )}
        </span>
      </div>
    );
  }

  return <div>{content}</div>;
};

Reset.propTypes = {
  initResetPassword: PropTypes.func,
  successMsg: PropTypes.string,
  forgotPassword: PropTypes.func.isRequired,
  resetPayload: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

Reset.defaultProps = {
  initResetPassword: null,
  loading: false,
  successMsg: null,
};

const mapStateToProps = (state, auth) => ({
  successMsg: state.auth.resetSuccessMsg,
  loading: auth.resetLoading,
  resetPayload: state.auth.resetPayload,
});

const mapDispatchToProps = (dispatch) => ({
  initResetPassword: (formData) => dispatch(resetPassword(formData)),
  forgotPassword: (formData) => dispatch(forgotPassword(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reset);