export const updateState = (oldState, updatedProperties) => ({
  ...oldState,
  ...updatedProperties,
});

export const checkISODate = str =>
  // eslint-disable-next-line max-len
  /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])\.[0-9]{3}Z$/.test(
    str,
  );

export const getFirstCharOfStrings = str =>
  str
    ? str
      .match(/\b(\w)/g)
      .join('')
      .toUpperCase()
    : '';
