import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import auth from './reducer-auth';
import company from './reducer-company';
import engine from './reducer-engine';
import test from './reducer-test';
import timer from './reducer-timer';

const rootReducer = combineReducers({
  form,
  company,
  auth,
  test,
  engine,
  timer,
});

export default rootReducer;
