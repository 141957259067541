import bcrypt from 'bcryptjs';

export const hashPassword = async password => {
  const saltRounds = 10;

  try {
    const hashedPassword = await bcrypt.hash(password, saltRounds);
    return hashedPassword;
  } catch (error) {
    console.error('Error hashing password:', error);
  }
};

export const verifyPassword = async (plainPassword, hashedPassword) => {
  try {
    const isMatch = await bcrypt.compare(plainPassword, hashedPassword);
    if (!isMatch) {
      console.log('Password does not match!');
    }
    return isMatch;
  } catch (error) {
    console.error('Error verifying password:', error);
  }
};
