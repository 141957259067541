import { toast } from 'react-toastify';

import { TOAST_TEST_DOWNLOADING } from '../../constants/data';
import { updateState } from '../../lib/utils';
import { testActions as types } from '../actionTypes';

const defaultState = {
  tests: [],
  loading: false,
  error: null,
  initRefId: '',
  initiating: false,
  launching: false,
  launchError: null,
  deleting: false,
  deleteId: null,
  downloadTokens: [],
  downloading: false,
  expandAccordion: false,
  activeGrp: {},
};

const handleDownloadTokens = (state, payload) => {
  let tokens = [...state.downloadTokens, payload.testId];
  if (payload.isRemove) {
    tokens = tokens.filter(t => t !== payload.testId);
  }
  const downloading = tokens.length > 0;
  if (!downloading) {
    toast.dismiss(TOAST_TEST_DOWNLOADING);
  }
  return updateState(state, { downloading, downloadTokens: tokens });
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_TEST:
      return updateState(state, { ...action.payload });

    case types.INIT_TEST:
      return updateState(state, { ...action.payload });

    case types.LAUNCH_TEST_BY_CODE:
      return updateState(state, { ...action.payload });

    case types.DELETE_TEST:
      return updateState(state, { ...action.payload });

    case types.SET_DOWNLOAD_TOKENS:
      return handleDownloadTokens(state, action.payload);

    case types.SET_TEST_ACCORDION:
      return updateState(state, { ...action.payload });

    case types.SET_LAUNCH_DETAILS:
      return updateState(state, { ...action.payload });

    default:
      return state;
  }
};
