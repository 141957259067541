import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';
import { Loader } from '../components/domain';
import { AppContext } from '../contexts';
import { useNetworkStatus } from '../hooks';
import { validateToken } from '../redux/actions/action-auth';
import { fetchCompany } from '../redux/actions/action-company';


const Routes = ({ isLoggedIn, validating, loading, ...props }) => {
  const isOnline = useNetworkStatus();

  const handleCopyCutPaste = (e) => {
    e.preventDefault();
  };

  const allowCopyCutPasteInInputs = (e) => {
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener('copy', handleCopyCutPaste);
    document.addEventListener('cut', handleCopyCutPaste);
    document.addEventListener('paste', handleCopyCutPaste);

    document.addEventListener('copy', allowCopyCutPasteInInputs, true);
    document.addEventListener('cut', allowCopyCutPasteInInputs, true);
    document.addEventListener('paste', allowCopyCutPasteInInputs, true);

    return () => {
      document.removeEventListener('copy', handleCopyCutPaste);
      document.removeEventListener('cut', handleCopyCutPaste);
      document.removeEventListener('paste', handleCopyCutPaste);

      document.removeEventListener('copy', allowCopyCutPasteInInputs, true);
      document.removeEventListener('cut', allowCopyCutPasteInInputs, true);
      document.removeEventListener('paste', allowCopyCutPasteInInputs, true);
    };
  }, []);

  useEffect(() => {
    props.fetchCompany();
    props.validateToken();
  }, []);

  useEffect(() => {
    if (isOnline) {
      toast.info('You\'re connected to internet', {
        toastId: 'ONLINE',
        autoClose: 500,
      });
    } else {
      toast.error('You\'re offline, continue taking the test', {
        toastId: 'OFFLINE',
        autoClose: 500,
      });
    }
  }, [isOnline]);

  if (validating || loading) {
    return <Loader />;
  }

  return (
    <AppContext.Provider value={{ isOnline }}>
      {isLoggedIn ? <AppRouter /> : <AuthRouter />}
    </AppContext.Provider>
  );
};

Routes.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  validating: PropTypes.bool.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapState = ({ auth, company }) => ({
  isLoggedIn: auth.isLoggedIn,
  validating: auth.validating,
  loading: company.loading,
});

const mapDispatch = dispatch => ({
  fetchCompany: () => dispatch(fetchCompany()),
  validateToken: () => dispatch(validateToken()),
});

export default connect(mapState, mapDispatch)(Routes);
