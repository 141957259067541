import { updateState } from '../../lib/utils';
import { companyActions as types } from '../actionTypes';

const defaultState = {
  companyDetails: {},
  loading: true,
  error: null,
};

export default (state = defaultState, actions) => {
  switch (actions.type) {
    case types.FETCH_COMPANY:
      return updateState(state, { ...actions.payload });

    default:
      return state;
  }
};
