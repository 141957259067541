import { getDownloads, storeDownloads } from './dexie';

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export const getImageUrl = async imageUrl => {
  const imageBlob = await getDownloads(imageUrl);
  if (imageBlob) {
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
  } else {
    alert('Image not found in IndexedDB');
  }
};

export const handleDownloadImage = async imageUrl => {
  try {
    const response = await fetch(imageUrl, { mode: 'cors' });
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error downloading the image:', error);
  }
};

export const extractUrlsFromHtml = htmlString => {
  const urlPattern = /https?:\/\/[^\s"']+/g;
  return htmlString.match(urlPattern) || [];
};

const extractUrlsFromJson = json => {
  let urls = [];

  const traverse = value => {
    if (typeof value === 'string') {
      urls = urls.concat(extractUrlsFromHtml(value));
    } else if (Array.isArray(value)) {
      value.forEach(traverse);
    } else if (typeof value === 'object' && value !== null) {
      Object.entries(value).forEach(traverse);
    }
  };

  traverse(json);
  return urls;
};

export const downloadUrlAndChangeToBlob = async (jsonString, testToken) => {
  const urls = await extractUrlsFromJson(JSON.parse(jsonString));
  let tempJson = jsonString;
  const setUrls = [...new Set(urls)];
  if (setUrls?.length > 0) {
    await Promise.all(
      setUrls.map(async url => {
        let exactUrl = url.replace(/\\"+$/, '');
        const blob = await handleDownloadImage(exactUrl);
        const base64String = await blobToBase64(blob);
        const base64Url = `data:${blob.type};base64,${base64String}`;
        await storeDownloads(url, {
          base64: base64Url,
          blob,
          token: testToken,
        });
      }),
    );
  }

  return JSON.parse(tempJson);
};

export const replaceUrlsWithBlobs = async htmlString => {
  const urls = extractUrlsFromHtml(htmlString);

  for (let url of urls) {
    const item = await getDownloads(url);
    if (item.blob) {
      // const dataUrl = await blobToDataURL(item.blob);

      // const blobUrl = URL.createObjectURL(item.blob);
      htmlString = htmlString.replace(url, item.base64);
    }
  }

  return htmlString;
};

export const blobToDataURL = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
