import Dexie from 'dexie';

import { verifyPassword } from './bcrypt';

const DATABASE = 'kananco';

const db = new Dexie(DATABASE);

db.version(1).stores({
  company: '++id, &_id, companyCode, companyName',
  users: '++id, &_id, token, loginId, password',
  tests: `++id, _id, userId, testId, testCode, testAccessCode, courseId, 
    [testId+courseId], token, &[_id+userId], [parentId+userId]`,
  downloads: '++id, url, blob, token',
  tracks: '++id, &_id, itemId, sectionId, testId, token, userId',
  testJson: 'id, TestID, QuestionPaperID, token, userId',
  launch: 'id, token, userId',
});

export const checkAndInsertRecord = async (tableName, data) => {
  const response = await db[tableName].get({ _id: data._id });

  if (response?.id) {
    const newRes = await updateRecord(tableName, response?.id, data);

    return {
      success: true,
      data: newRes,
      errorCode: 'Exits',
    };
  }

  const newId = await db[tableName].add(data);

  return {
    success: true,
    data: { id: newId },
    errorCode: null,
  };
};

export const addData = async (tableName, data) => {
  try {
    const response = await db[tableName].add(data);

    if (response.message?.includes('Key already exists')) {
      throw new Error('Key already exists');
    }

    return {
      success: true,
      data: { id: response },
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

export const getAllLocalTests = async userId => {
  if (!userId) {
    return [];
  }
  const tests = await db.tests.where({ userId })?.toArray();

  return tests.map(t => ({
    ...t,
    id: t._id,
  }));
};

export const getAllData = async tableName => {
  return await db[tableName].toArray();
};

export const getDataById = async (tableName, id) => {
  return await db[tableName].get(id);
};

export const deleteData = async (tableName, id) => {
  await db[tableName].delete(id);
};

export const deleteDatabase = async () => {
  await Dexie.delete(DATABASE);
};

export const dbValidateToken = async (tableName, id) => {
  return await db[tableName].get({ _id: id });
};

export const insertBulkData = async (tableName, data) => {
  try {
    await db[tableName].bulkAdd(data);
  } catch (error) {
    if (error.name === 'BulkError') {
      // error.failures.forEach((failure, index) => {
      //   console.info(`Failed record ${index}:`, failure);
      // });
    } else {
      console.log('Error adding data:', error.message);
    }
  }
};

export const putBulkData = async (tableName, data) => {
  try {
    await db[tableName].bulkPut(data);
  } catch (error) {
    if (error.name === 'BulkError') {
      // error.failures.forEach((failure, index) => {
      //   console.info(`Failed record ${index}:`, failure);
      // });
    } else {
      console.log('Error adding data:', error.message);
    }
  }
};

export const getLoginData = async ({ loginId, password }) => {
  const user = await db.users.where('loginId').equals(loginId).first();
  if (!user?.id) {
    return {
      success: false,
      message:
        'You appear to be offline. Please connect to the internet to proceed with login',
      data: {},
    };
  }
  const hasMatch = await verifyPassword(password, user.password);
  if (!hasMatch) {
    return {
      success: false,
      message: 'Invalid Password',
      data: {},
    };
  }
  return {
    success: true,
    message: 'Success',
    data: user,
  };
};

export const getTestsByCode = async (code, userId) => {
  const test = await db.tests.get({ testCode: code, userId });
  if (!test?.id) {
    return {};
  }
  const subItems = await db.tests.where('[parentId+userId]').equals([test._id, userId]).toArray();

  return {
    ...test,
    subItems: subItems
  };
};

export const getTestsAccessByCode = async (code, userId) => {
  const test = await db.tests.get({ testAccessCode: code, userId });

  if (!test) {
    return {};
  }
  return {
    ...test,
    id: test._id,
  };
};

export const checkIndexedDB = async () => {
  try {
    const db = new Dexie('HealthCheckDB');

    db.version(1).stores({ test: 'id' });

    await db.test.add({ id: 1, name: 'test' });

    const item = await db.test.get(1);

    await db.test.delete(1);

    await db.delete();

    return item ? true : false;
  } catch (error) {
    console.error('Connection error:', error);
    return false;
  }
};

export const storeDownloads = async (url, { blob, base64, token }) => {
  try {
    await db.downloads.put({ url, blob, base64, token });
  } catch (error) {
    console.error('Failed to store image:', error.message);
  }
};

export const getDownloads = async url => {
  try {
    const item = await db.downloads.get({ url });
    return item;
  } catch (error) {
    console.error('Failed to retrieve item:', error);
  }
};

export const storeTestLaunchData = async ({
  testJson,
  studentTestData,
  tracks,
  testData,
  userData,
  companyData,
  token,
  completed,
  userId,
  testId,
}) => {
  try {
    let id = token;
    await putBulkData(
      'tracks',
      tracks.map(t => {
        let temp = { ...t };
        delete temp.id;
        return { ...temp, _id: t.id, token };
      }),
    );

    if (studentTestData.testId) {
      await db.tests.where('[_id+userId]').equals([testId, userId]).modify({
        token,
        studentTestDetails: studentTestData,
      });
    }

    await db.testJson.put({ ...testJson, id, token, userId });

    await db.launch.put({
      testData,
      userData,
      companyData,
      id,
      token,
      completed,
      userId,
    });
  } catch (error) {
    console.error('Failed to store test launch data:', error);
  }
};

export const getTestLaunchData = async ({ token }) => {
  try {
    const launchData = await db.launch.get({ token });
    const test = await db.tests.get({ token });

    if (!test) {
      throw new Error('Invalid Test');
    }

    const testJson = await db.testJson.get({ token });
    const tracks = await db.tracks.where({ token }).toArray();

    const testData = {
      ...launchData,
      studentTestData: test.studentTestDetails,
    };

    delete testData.id;
    delete testData.token;

    return {
      success: true,
      data: {
        ...testData,
        testJson,
        tracks: tracks.sort((a, b) => a.trackNumber - b.trackNumber),
      },
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const deleteTestLaunchData = async ({ token }) => {
  try {
    const testItem = await db.tests.get({ token });

    await db.launch.where('token').equals(token).delete();
    await db.testJson.where('token').equals(token).delete();
    await db.tracks.where('token').equals(token).delete();
    await db.downloads.where('token').equals(token).delete();

    if (testItem?.id)
      await dexieSyncTest({ testId: testItem._id, userId: testItem.userId });

    return true;
  } catch (error) {
    return false;
  }
};

export const getTestToken = async ({ testId, courseId, userId }) => {
  try {
    const testData = await db.tests.get({ _id: testId, userId, courseId });
    return { success: true, token: testData.token };
  } catch (error) {
    return { success: false };
  }
};

export const updateRecord = async (tableName, id, params) => {
  return await db[tableName].update(id, { id, ...params });
};

export const putTracks = async (tracks, token) => {
  await putBulkData(
    'tracks',
    tracks.map(t => ({ ...t, token })),
  );
};

export const getAllTracks = async ({ testId, userId }) => {
  let testData = await db.tests.get({ _id: testId, userId });

  if (!testData?.id) {
    return { success: false, error: 'Invalid test' };
  }

  testData = {
    ...testData,
    id: testData._id,
  };

  const tracks = await db.tracks.where({ token: testData.token }).toArray();

  return { success: true, tracks, testData };
};

export const dexieEndTest = async ({ token }) => {
  const testItem = await db.tests.get({ token });

  if (!testItem.id) {
    return { success: false };
  }

  await db.tests.update(testItem.id, {
    'studentTestDetails.isSynced': 0,
    'studentTestDetails.isActive': 0,
    'studentTestDetails.completedDatetime': new Date().toISOString(),
  });

  await db.launch.update(token, { id: token, completed: true });
  return { success: true };
};

export const dexieSyncTest = async ({ testId, userId }) => {
  await db.tests.where('[_id+userId]').equals([testId, userId]).modify({
    'studentTestDetails.isSynced': 1,
    'studentTestDetails.isActive': 0,
    'studentTestDetails.syncedOn': new Date().toISOString(),
  });
};

export const deleteDexieTestAndTracks = async ({ id, token, userId }) => {
  try {
    if (token) {
      await db.launch.where('token').equals(token).delete();
      await db.testJson.where('token').equals(token).delete();
      await db.tracks.where('token').equals(token).delete();
      await db.downloads.where('token').equals(token).delete();
    }
    await db.tests.where('[_id+userId]').equals([id, userId]).delete();

    return true;
  } catch (error) {
    return false;
  }
};

export const updateTestById = async (testId, userId, params) => {
  return await db.tests.where('[_id+userId]').equals([testId, userId]).modify({
    ...params
  });
};

export const resetTestById = async (testId, userId) => {
  await db.tests.where('[_id+userId]').equals([testId, userId]).modify({
    'studentTestDetails.isSynced': 0,
    'studentTestDetails.isActive': 1,
  });
};