import React, { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { submitEvaluationTest } from '../../redux/actions/action-engine';
import { deleteTest, resetTest, setTestAccordion, testDownload } from '../../redux/actions/action-test';
import { Tooltip } from '../domain';

const TestCard = ({
  tests,
  downloadTokens,
  downloading,
  handleSyncTest,
  syncId,
  syncing,
  deleteId,
  deleting,
  expandAccordion,
  activeGrp,
  ...props
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (downloading && downloadTokens.length > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [downloading, downloadTokens]);

  const testStatus = useCallback(
    item => {
      const { studentTestDetails } = item;
      const globalDownloading = downloadTokens.includes(item.id) && downloading;

      if (deleteId && item.id === deleteId && deleting) {
        return <span>Deleting...</span>;
      }

      if (item.isDownloading === 2 || (!globalDownloading && item.isDownloading === 1)) {
        return <span className="text-danger">Download failed</span>;
      }
      if (item.isDownloading === 1) {
        return <span>Downloading...</span>;
      }
      if (!studentTestDetails?.id) {
        return <span>Downloaded - Start / Resume test</span>;
      }
      if (studentTestDetails.isActive === 1) {
        return <span>Downloaded - Start / Resume test</span>;
      }
      if (studentTestDetails.isSynced === 0) {
        return <span>Test Completed - Not Uploaded</span>;
      }
      return <span>Test Completed - Uploaded</span>;
    },
    [downloadTokens],
  );

  const launchBtnText = useCallback(item => {
    const { studentTestDetails } = item;
    const downloading = (item.isDownloading === 1);
    const globalDownloading = downloadTokens.includes(item.id) && downloading;

    if (item.isDownloading === 2 || (!globalDownloading && item.isDownloading === 1)) {
      return (
        <span
          className={'text-danger'}
          onClick={() => {
            props.testDownload({ testId: item.id, courseId: item.courseId, retry: true });
          }}>
          <span className="icon far fa-undo me-2"></span>
          Retry
        </span>
      );
    }

    if (!studentTestDetails?.id) {
      return (
        <>
          {item.isEvaluationOnly ? (
            <span
              className={`${downloading ? 'text-warning' : 'text-success'}`}
              onClick={() => {
                if (!downloading && !props.submitting) {
                  props.submitEvaluationTest(item);
                }
              }}>
              <span className={`icon ${downloading ? 'far fa-download' : 'far fa-check-circle'} me-2`}></span>
              {(props.submitting && props.testId === item._id) ? 'Submitting...' : (
                <>{downloading ? 'Downloading...' : 'Mark as done'}</>
              )}
            </span>
          ) : (
            <span
              className={`${downloading ? 'text-warning' : 'text-success'}`}
              onClick={() => {
                if (!downloading) {
                  navigate('/test-launch');
                }
              }}>
              <span className={`icon ${downloading ? 'far fa-download' : 'far fa-rocket'} me-2`}></span>
              {downloading ? 'Downloading...' : 'Start / Resume test'}
            </span>
          )}
        </>
      );
    }

    if (studentTestDetails.isActive === 1) {
      return (
        <>
          {item.isEvaluationOnly ? (
            <span
              className={`${downloading ? 'text-warning' : 'text-success'}`}
              onClick={() => {
                if (!downloading && !props.submitting) {
                  props.submitEvaluationTest(item);
                }
              }}
            >
              <span className={`icon ${downloading ? 'far fa-download' : 'far fa-check-circle'} me-2`}></span>
              {(props.submitting && props.testId === item._id) ? 'Submitting...' : (
                <>{downloading ? 'Downloading...' : 'Mark as done'}</>
              )}
            </span>
          ) : (
            <span
              className={`${downloading ? 'text-warning' : 'text-secondary'}`}
              onClick={() => {
                if (!downloading) {
                  navigate('/test-launch');
                }
              }}>
              <span className={`icon ${downloading ? 'far fa-download' : 'far fa-rocket'} me-2`}></span>
              {downloading ? 'Downloading...' : 'Start Test / Resume'}
            </span>
          )}
        </>
      );
    }
    if (studentTestDetails.isSynced === 0) {
      return (
        <span
          className="text-warning"
          onClick={() => {
            if (!(syncId && item.id === syncId && syncing)) {
              handleSyncTest(item);
            }
          }}>
          <span className="icon far fa-upload me-2"></span>
          {syncId && item.id === syncId && syncing
            ? 'Please wait...'
            : 'Upload Now'}
        </span>
      );
    }
    return (
      <>
        <span className="icon fas fa-check-circle me-2 text-success"></span>
        <span className="text-success">Uploaded</span>
      </>
    );
  }, [downloadTokens, syncId]);

  const getCounts = useCallback(
    (item) => {
      let counts = {
        completed: 0, uploaded: 0, failed: 0, notuploaded: 0, unattended: 0
      };
      item.subItems.map(s => {
        if (s.isDownloading === 2 || (!downloading && s.isDownloading === 1)) {
          counts.failed += 1;
        } else if (s.studentTestDetails?.isActive === 0) {
          if (s.studentTestDetails?.isSynced !== 0) {
            counts.completed += 1;
          } else {
            counts.completed += 1;
            counts.notuploaded += 1;
          }

        } else if (s.studentTestDetails?.isSynced === 0) {
          counts.notuploaded += 1;
        }
      });

      counts.uploaded = counts.completed - counts.notuploaded;
      counts.unattended = item.subItems.length - (counts.completed + counts.failed);
      return counts;
    },

    [downloading, downloadTokens],
  );

  return (
    <div className="test-card">
      {tests.map(item => {
        const active = (activeGrp.id === item.id && expandAccordion);
        const counts = getCounts(item);

        return (
          <div
            className='test-group'
            key={item.id}>
            <div
              className={`grp-item ${active ? 'expand' : 'collapse'}`}
              role="presentation"
              onClick={() => {
                props.setTestAccordion(item, !expandAccordion);
              }}>
              <div className='grp-title'>
                <h6>{item.component?.title}</h6>
                {item.component?.description &&
                  <p>
                    <span className='fas fa-info-circle me-1' />
                    {item.component?.description}
                  </p>
                }
              </div>
              <p className="grp-count">
                <p>
                  {'Code - '}
                  <span>{item.testCode}</span>
                </p>
                <p className="grp-action">
                  <Tooltip
                    placement="top"
                    title="Test Unattended"
                    className='count completed'
                  >
                    {counts.unattended}
                    <span className={'icon far fa-clipboard-list'} />
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="Test Completed & Uploaded"
                    className='count uploaded'
                  >
                    {counts.uploaded}
                    <span className={'icon far fa-check-double'} />
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="Download Failed"
                    className='count failed'
                  >
                    {counts.failed}
                    <span className={'icon far fa-exclamation-circle'} />
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="Test Not Uploaded"
                    className='count completed'
                  >
                    {counts.notuploaded}
                    <span className={'icon far fa-upload'} />
                  </Tooltip>
                  <span className={`icon ms-2 fas ${active ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                </p>
              </p>
            </div>
            {item.subItems.length > 0 && active && (
              <div className={`test-child-grp ${active ? 'expand' : 'collapse'}`}>
                {item.subItems.map(sItem => (
                  <div
                    className="test-item"
                    key={sItem.id}>
                    <div className='test-title'>
                      <h6>{sItem.component?.title}</h6>
                      {sItem.component?.description &&
                        <p>
                          <span className='fas fa-info-circle me-1' />
                          {sItem.component?.description}
                        </p>
                      }
                    </div>
                    <p className="test-status">
                      {'Status - '}
                      {testStatus(sItem)}
                    </p>
                    <p className="launch-btn">
                      {launchBtnText(sItem)}
                      {/* <span
                      className="trash-icon ms-2 fas fa-trash"
                      role="presentation"
                      onClick={() => props.resetTest({ testId: sItem.testId })}></span> */}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}

    </div >
  );
};

TestCard.propTypes = {
  tests: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadTokens: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([null]),
  ]),
  syncId: PropTypes.string.isRequired,
  syncing: PropTypes.bool.isRequired,
  handleSyncTest: PropTypes.func.isRequired,
  deleteTest: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  deleteId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  testDownload: PropTypes.func.isRequired,
  setTestAccordion: PropTypes.func.isRequired,
  expandAccordion: PropTypes.bool.isRequired,
  activeGrp: PropTypes.object.isRequired,
  submitEvaluationTest: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  resetTest: PropTypes.func.isRequired,
  testId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
};

const mapState = ({ test, engine }) => ({
  tests: test.tests,
  loading: test.loading,
  downloading: test.downloading,
  downloadTokens: test.downloadTokens,
  syncId: engine.syncId,
  syncing: engine.syncing,
  deleting: test.deleting,
  deleteId: test.deleteId,
  expandAccordion: test.expandAccordion,
  activeGrp: test.activeGrp,
  submitting: engine.submitting,
  testId: engine.testId
});

const mapDispatch = dispatch => ({
  deleteTest: item => dispatch(deleteTest(item)),
  testDownload: (data) => dispatch(testDownload(data)),
  setTestAccordion: (grp, show) => dispatch(setTestAccordion(grp, show)),
  submitEvaluationTest: (item) => dispatch(submitEvaluationTest(item)),
  resetTest: (data) => dispatch(resetTest(data))
});

export default connect(mapState, mapDispatch)(TestCard);
