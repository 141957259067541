import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import QuestionPallete from '../QuestionPallete';
import { Button } from '../../domain';
import { TestEngineRootContext } from '../../../contexts';

const Navigator = ({ user, handleQuit, isFirstPsg, isLastPsg, ...props }) => {
  const { setEngineRootParams } = useContext(TestEngineRootContext);

  return (
    <>
      <div className="engine-body-navigator web-view">
        <p className="student-name">{user?.studentName}</p>
        <div className="qn-action-wrapper">
          <QuestionPallete />
          <div className="qn-actions">
            {!isFirstPsg && (
              <Button
                className="btn btn-prev"
                role="presentation"
                onClick={props.handlePrevQn}>
                Prev
              </Button>
            )}
            {isLastPsg ? (
              <Button
                className="btn btn-finish"
                role="presentation"
                disabled={props.ending}
                loading={props.ending}
                loadingText="Submitting..."
                onClick={() =>
                  setEngineRootParams(p => ({ ...p, showQuitModal: true }))
                }>
                Finish
              </Button>
            ) : (
              <Button
                className="btn btn-next"
                role="presentation"
                onClick={props.handleNextQn}>
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="engine-body-navigator mobile-view">
        <div className="qn-action-wrapper">
          <div
            className={`arrow-btn ${isFirstPsg ? 'disabled' : ''}`}
            role="presentation"
            onClick={() => {
              if (!isFirstPsg) props.handlePrevQn();
            }}>
            <span className="fas fa-arrow-left"></span>
          </div>
          <div className="qn-info">
            <p className="student-name">{user?.studentName}</p>
            <QuestionPallete toggle />
          </div>
          {isLastPsg ? (
            <Button
              className="btn btn-finish rounded-0"
              role="presentation"
              disabled={props.ending}
              loading={props.ending}
              loadingText="Submitting..."
              onClick={() =>
                setEngineRootParams(p => ({ ...p, showQuitModal: true }))
              }>
              Finish
            </Button>
          ) : (
            <div
              className="arrow-btn"
              role="presentation"
              onClick={props.handleNextQn}>
              <span className="fas fa-arrow-right"></span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Navigator.propTypes = {
  user: PropTypes.object.isRequired,
  handleQuit: PropTypes.func.isRequired,
  handlePrevQn: PropTypes.func.isRequired,
  handleNextQn: PropTypes.func.isRequired,
  isFirstPsg: PropTypes.bool.isRequired,
  isLastPsg: PropTypes.bool.isRequired,
  ending: PropTypes.bool.isRequired,
};

export default Navigator;
