import React from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';

const Loader = ({ message = null }) => (
  <div className="loader">
    <div className="lds-ripple">
      <div />
      <div />
    </div>
    {message && <p className="mb-0">{message}</p>}
  </div>
);

Loader.propTypes = {
  message: PropTypes.any,
};

export default Loader;
