import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderHtmlWithBlobs from '../RenderHtmlWithBlob';

const Essay = ({ question, handleInputValue, studentResponse, frameTrack }) => {
  useEffect(() => {
    if (typeof window?.MathJax !== 'undefined') {
      window.MathJax.typeset();
    }
  }, [question?.ItemID]);

  const studentAns = useMemo(
    () =>
      studentResponse.find(
        t =>
          t.sectionid === question.QuestionPaperSectionID &&
          t.itemId === question.ItemID,
      ),
    [question.ItemID, studentResponse],
  );

  return (
    <div
      className="type-essay-wrapper"
      key={question.ItemID}>
      {question.Items && (
        <div className="qn-question">
          <RenderHtmlWithBlobs htmlString={question.Items} />
        </div>
      )}
      <div className="qn-options">
        <textarea
          className="form-control"
          rows={15}
          defaultValue={studentAns?.enteredText}
          onChange={e => {
            handleInputValue(e, question);
          }}
          onBlur={e => {
            frameTrack(e, question);
          }}
        />
      </div>
    </div>
  );
};

Essay.propTypes = {
  question: PropTypes.object.isRequired,
  handleInputValue: PropTypes.func.isRequired,
  frameTrack: PropTypes.func.isRequired,
  studentResponse: PropTypes.array.isRequired,
};

const mapStateToProps = ({ engine }) => ({
  studentResponse: engine.studentResponse,
});

export default connect(mapStateToProps)(Essay);
