import axios from 'axios';

import { API_BASE_URL, TESTENGINE_API_BASE_URL } from '../constants/config';
import * as Cookies from '../lib/Cookie';

const baseUrl = `${API_BASE_URL}`;
const testEngineBaseUrl = `${TESTENGINE_API_BASE_URL}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

function checkResponse(res) {
  return res.response;
}

async function GET(params) {
  const { name, queryString } = params;
  const q = queryString ? `?${queryString}` : '';
  try {
    let url = baseUrl;
    axios.defaults.headers.common.Authorization = Cookies.get('quizky_token');
    if (params.testengine) {
      url = testEngineBaseUrl;
      axios.defaults.headers.common.Authorization = Cookies.get('engine_token');
    }
    const result = await axios({
      method: 'get',
      url: `${url}/${name}${q}`,
    }).then(response => response.data);
    return result;
  } catch (error) {
    return checkResponse(error);
  }
}

async function POST(params) {
  const { name, queryString, payload } = params;
  const q = queryString ? `?${queryString}` : '';
  try {
    let url = baseUrl;
    axios.defaults.headers.common.Authorization = Cookies.get('quizky_token');
    if (params.testengine) {
      url = testEngineBaseUrl;
      axios.defaults.headers.common.Authorization = Cookies.get('engine_token');
    }
    const result = await axios({
      method: 'post',
      url: `${url}/${name}${q}`,
      data: payload,
    }).then(response => response.data);
    return result;
  } catch (error) {
    return checkResponse(error);
  }
}

async function PUT(params) {
  const { name, queryString, payload } = params;
  const q = queryString ? `?${queryString}` : '';
  try {
    let url = baseUrl;
    axios.defaults.headers.common.Authorization = Cookies.get('quizky_token');
    if (params.testengine) {
      url = testEngineBaseUrl;
      axios.defaults.headers.common.Authorization = Cookies.get('engine_token');
    }
    const result = await axios({
      method: 'put',
      url: `${url}/${name}${q}`,
      data: payload,
    }).then(response => response.data);
    return result;
  } catch (error) {
    return checkResponse(error);
  }
}

async function PATCH(params) {
  const { name, queryString, payload } = params;
  const q = queryString ? `?${queryString}` : '';
  try {
    let url = baseUrl;
    axios.defaults.headers.common.Authorization = Cookies.get('quizky_token');
    if (params.testengine) {
      url = testEngineBaseUrl;
      axios.defaults.headers.common.Authorization = Cookies.get('engine_token');
    }
    const result = await axios({
      method: 'patch',
      url: `${url}/${name}${q}`,
      data: payload,
    }).then(response => response.data);
    return result;
  } catch (error) {
    return checkResponse(error);
  }
}

async function DELETE(params) {
  const { name, queryString, payload } = params;
  const q = queryString ? `?${queryString}` : '';
  try {
    let url = baseUrl;
    axios.defaults.headers.common.Authorization = Cookies.get('quizky_token');
    if (params.testengine) {
      url = testEngineBaseUrl;
      axios.defaults.headers.common.Authorization = Cookies.get('engine_token');
    }
    const result = await axios({
      method: 'delete',
      url: `${url}/${name}${q}`,
      data: payload,
    }).then(response => response);
    return result;
  } catch (error) {
    return checkResponse(error);
  }
}

export default {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
};
