export const set = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const get = cname => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const erase = name => {
  document.cookie = `${name}=; Max-Age=-99999999;`;
};

export const eraseByName = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const eraseAllSync = async () =>
  await new Promise((resolve, reject) => {
    try {
      const cookies = document.cookie.split(';');
      let flag = false;

      for (let i = 0; i < cookies.length; i += 1) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        if (i === cookies.length - 1) {
          flag = true;
        }
      }
      const intervalId = setInterval(() => {
        if (flag) {
          clearInterval(intervalId);
          resolve();
        }
      }, 1);
    } catch (error) {
      reject(error);
    }
  });
