import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { AlertBox, Button } from '../components/domain';
import { validateResetPasswordForm } from '../lib/formValidations';
import { PasswordInput, InputField } from '../components/domain/ReduxFormComponents';

const ResetPasswordForm = (props) => {
  return (
    <form className="forgot-passwd-form" onSubmit={props.handleSubmit}>
      <p>Enter the OTP received in your registered email/mobile number</p>
      {props.errorMsg && <AlertBox show message={props.errorMsg} type="danger" />}
      <div className="mb-3">
        <Field name="otp" component={InputField} type="text" className="form-control login-form-control" required placeholder="Enter the OTP" />
      </div>
      <div className="mb-3">
        <Field name="password" component={PasswordInput} type="text" className="form-control" required placeholder="Password" showIcon />
      </div>
      <div className="mb-3">
        <Field name="cpassword" component={PasswordInput} type="text" className="form-control" required placeholder="Confirm password" showIcon />
      </div>
      <div className="d-grid gap-2">
        <Button type="submit" disabled={props.loading} className="btn btn-success rounded-5 login-btn mt-2">
          {props.loading ? 'Please wait...' : 'Submit'}
        </Button>
      </div>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  errorMsg: PropTypes.string
};

ResetPasswordForm.defaultProps = {
  loading: false,
  handleSubmit: null,
  errorMsg: null
};

const ResetPasswordRedux = reduxForm({
  form: 'resetPassword',
  validate: validateResetPasswordForm
})(ResetPasswordForm);

const mapStateToProps = ({ auth }) => ({
  errorMsg: auth.resetErrorMsg,
  loading: auth.resetLoading,
});

export default connect(mapStateToProps)(ResetPasswordRedux);