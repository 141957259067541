import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { validateTestCodeForm } from '../lib/formValidations';
import { Button } from './domain';
import { InputField } from './domain/ReduxFormComponents';
import { connect } from 'react-redux';

const Form = props => {
  return (
    <form className="row" onSubmit={props.handleSubmit}>
      <div className="col-md-4 col-9">
        <Field
          type="testCode"
          name="testCode"
          className="form-control"
          component={InputField}
          placeholder="Enter your test code"
        />
      </div>
      <div className="col-md-2 col-2">
        <Button
          type="submit"
          className="btn btn-primary"
          loading={props.loading}
          loadingText="Fetching..."
          disabled={props.loading}>
          Go
        </Button>
      </div>
    </form>
  );
};

const TestCodeForm = reduxForm({
  form: 'TestForm',
  validate: validateTestCodeForm,
})(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapState = ({ test }) => ({
  loading: test.loading,
});

export default connect(mapState)(TestCodeForm);
