/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

import Button from './Button';

const ResetAlert = ({ onSuccess, onClose }) => {
  return (
    <div className="logout-box">
      <div className="alert-msg">
        <p className="title">{'Are you sure you want to reset settings?'}</p>
        <p className="description">
          By resetting, all settings will be erased and you will be logged out
        </p>
      </div>
      <div className="action-btns">
        <Button
          className="btn btn-danger"
          role="presentation"
          onClick={onSuccess}>
          Yes
        </Button>
        <Button
          className="btn btn-dark"
          role="presentation"
          onClick={onClose}>
          No
        </Button>
      </div>
    </div>
  );
};

ResetAlert.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ResetAlert;
