import React from 'react';

import PropTypes from 'prop-types';

const strPadLeft = (string, pad, length) =>
  (new Array(length + 1).join(pad) + string).slice(-length);

const convertSeconds = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${strPadLeft(hours, 0, 2)}:${strPadLeft(minutes, 0, 2)}:${strPadLeft(remainingSeconds, 0, 2)}`;
};

const convertMinSeconds = time => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  if (seconds <= 0) {
    return `${minutes}`;
  }
  return `${minutes}:${strPadLeft(seconds, '0', 2)}`;
};

const Timer = ({ minTimer = 0, ...props }) => (
  <div className="timer">
    {props.seconds < 10 ? (
      <p className="text-danger mb-0">{convertSeconds(props.seconds || 0)}</p>
    ) : (
      <p className="mb-0">{convertSeconds(props.seconds || 0)}</p>
    )}
    {minTimer > 0 && (
      <span className="badge badge-warning">
        {`You can submit after ${convertMinSeconds(minTimer)} minutes`}
      </span>
    )}
  </div>
);

Timer.propTypes = {
  seconds: PropTypes.number.isRequired,
  minTimer: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
};

export default Timer;
