import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import '../../../styles/Layout.scss';
import { HeaderContext } from '../../../contexts';
import { Outlet } from 'react-router-dom';
import MenuBar from './MenuBar';
import useScreenProps from '../../../hooks/useScreenProps';

const Layout = ({ children }) => {
  const [header, setLayoutHeader] = useState({});
  const { isMobileView } = useScreenProps();

  useEffect(() => {
    const updateVh = () => {
      const height = window.innerHeight;
      const contentHeight = isMobileView ? height - 175 : height - 105;
      document.documentElement.style.setProperty('--content-height', `${contentHeight}px`);
    };

    updateVh();
    window.addEventListener('resize', updateVh);

    return () => {
      window.removeEventListener('resize', updateVh);
    };
  }, [isMobileView]);

  return (
    <div className="container-fluid p-0">
      <div className="layout-body g-0">
        <React.Fragment>
          {!isMobileView && <Sidebar />}
          <HeaderContext.Provider
            value={{
              ...header,
              setLayoutHeader,
            }}>
            <main className="layout-container">
              <Header />
              <div id="layout-content" className="content">
                <div className="app">
                  <Outlet />
                </div>
              </div>
            </main>
          </HeaderContext.Provider>
          {isMobileView && <MenuBar />}
        </React.Fragment>
      </div>
    </div>
  );
};

export default Layout;
