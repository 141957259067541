import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  replaceUrlsWithBlobs,
} from '../../lib/jsonurlToBlob';

const RenderHtmlWithBlobs = ({ htmlString, className = '' }) => {
  const [modifiedHtml, setModifiedHtml] = useState(null);

  useEffect(() => {
    const processHtml = async () => {
      const newHtmlString = await replaceUrlsWithBlobs(htmlString);
      setModifiedHtml(newHtmlString);
    };

    processHtml();

    return () => {
      // if (modifiedHtml) {
      //   const blobUrls = extractUrlsFromHtml(modifiedHtml);
      //   blobUrls.forEach(blobUrl => URL.revokeObjectURL(blobUrl));
      // }
    };
  }, [htmlString]);

  return (
    <p
      className={className}
      dangerouslySetInnerHTML={{ __html: modifiedHtml }}
    />
  );
};

RenderHtmlWithBlobs.propTypes = {
  htmlString: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

export default RenderHtmlWithBlobs;
