import { checkOnline } from '../../hooks/useNetworkStatus';
import { checkAndInsertRecord, getDataById } from '../../lib/dexie';
import Service from '../../lib/Service';
import { companyActions as types } from '../actionTypes';

const setPageDetails = data => {
  try {
    document.title = data.companyName;
    // if (data.faviconURL) {
    //   const favicon = document.getElementById('favicon');
    //   favicon.href = data.faviconURL;
    // }
  } catch (e) {
    console.log(e, 'Error in setting company details');
  }
};

export const fetchCompany = () => async dispatch => {
  try {
    const isOnline = checkOnline();

    const defaultCompanyCode = 'kananprep';
    const domainName = window.location.hostname;
    const segments = domainName.split('.');
    const companyCode =
      segments[0] === 'localhost' ? defaultCompanyCode : segments[0];

    dispatch({
      type: types.FETCH_COMPANY,
      payload: { loading: true, companyDetails: {} },
    });
    let resData = {};

    if (isOnline) {
      resData = await Service.GET({
        name: 'getcompany',
        queryString: `companyCode=${companyCode}&hostName=${domainName}`,
      }).then(res => {
        if (res.success) {
          return res.data;
        }
        throw new Error(res.message);
      });
    } else {
      resData = await getDataById('company', companyCode);
    }

    dispatch({
      type: types.FETCH_COMPANY,
      payload: { companyDetails: resData, loading: false },
    });
    setPageDetails(resData);

    if (resData.companyCode) {
      await checkAndInsertRecord('company', {
        ...resData,
        _id: resData.companyCode,
      });
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_COMPANY,
      payload: { error: error.message, companyDetails: {}, loading: false },
    });
  }
};
