import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { validateTestLaunchForm } from '../lib/formValidations';
import { AlertBox, Button } from './domain';
import { InputField } from './domain/ReduxFormComponents';
import { connect } from 'react-redux';

const Form = ({ launchError, launching, ...props }) => {
  return (
    <div className="test-launch-form">
      {launchError && <AlertBox show message={launchError} type="danger" />}
      <form className="" onSubmit={props.handleSubmit}>
        <div className="col mb-3">
          <label
            className="form-label test-access-label"
            htmlFor="testAccessCode">
            Test Access Code
          </label>
          <Field
            type="testAccessCode"
            name="testAccessCode"
            className="form-control"
            component={InputField}
            placeholder="Enter your code"
          />
        </div>
        <div className="d-grid gap-2">
          <Button
            type="submit"
            className="btn btn-primary rounded-0 text-white"
            loading={launching}
            loadingText="Launching..."
            disabled={launching}>
            Launch Test
          </Button>
        </div>
      </form>
    </div>
  );
};

const TestLaunchForm = reduxForm({
  form: 'TestLaunchForm',
  validate: validateTestLaunchForm,
})(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  launching: PropTypes.bool.isRequired,
  launchError: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
};

const mapState = ({ test }) => ({
  launching: test.launching,
  launchError: test.launchError,
});

export default connect(mapState)(TestLaunchForm);
