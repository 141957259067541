import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/ReduxFormComponents.scss';

export const InputField = ({
  input,
  placeholder,
  type,
  className,
  disabled,
  meta: { touched, error, warning },
  labelName,
  ...props
}) => (
  <>
    <div className="input-field">
      {labelName && (
        <label htmlFor={input.name} className="form-label">
          {labelName}
        </label>
      )}
      <input
        {...input}
        autoComplete="none"
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        className={`${className} ${touched && error ? 'is-invalid' : ''}`}
      />
      {props.iconInput === 'profile' && (
        <i className="iconStyle far fa-light fa-user" />
      )}
      {props.iconInput === 'email' && (
        <i className="iconStyle far fa-light fa-at" />
      )}
      {props.iconInput === 'mobile' && (
        <i className="iconStyle far fa-light fa-phone" />
      )}
      {touched && error && <div className="invalid-feedback">{error}</div>}
      {touched && warning && (
        <span className="redux-form-warning">
          <i className="fas fa-exclamation-circle" />
          {warning}
        </span>
      )}
    </div>
  </>
);

export const PasswordInput = ({
  input,
  placeholder,
  className,
  disabled,
  showHelp,
  iconInput,
  meta: { touched, error, warning },
}) => {
  const [showPassword, toggleShowPassword] = useState(false);

  return (
    <div className="password-field">
      <input
        autoComplete="none"
        disabled={disabled}
        {...input}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        className={`${className} ${touched && error ? 'is-invalid' : ''}`}
      />
      {iconInput && <i className="iconStyle far fa-lock-alt" />}
      <i
        role="presentation"
        onClick={() => {
          toggleShowPassword(!showPassword);
        }}
        className={`password-eye far fa-${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
      />
      {touched && error && <div className="invalid-feedback">{error}</div>}
      {touched && warning && (
        <span className="redux-form-warning">
          <i className="fas fa-exclamation-circle" />
          {warning}
        </span>
      )}
      {showHelp && (
        <small className="text-muted">
          A strong password with minimum of 6 characters is suggested.
        </small>
      )}
    </div>
  );
};

PasswordInput.defaultProps = {
  placeholder: null,
  className: null,
  disabled: false,
  showHelp: false,
};

PasswordInput.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  showHelp: PropTypes.bool,
};

export const TextArea = ({
  input,
  placeholder,
  rows,
  resizable,
  className,
  meta: { touched, error, warning },
}) => (
  <>
    <textarea
      {...input}
      rows={rows}
      placeholder={placeholder}
      className={`${className} ${touched && error ? 'is-invalid' : ''}`}
      style={resizable ? {} : { resize: 'none' }}
    />
    {touched && error && <div className="invalid-feedback">{error}</div>}
    {touched && warning && (
      <span className="redux-form-warning">
        <i
          className="fas fa-exclamation-circle"
          data-toggle="tooltip"
          data-placement="top"
          title={warning}
        />
      </span>
    )}
  </>
);

export const SelectField = ({
  input,
  className,
  children,
  disabled,
  meta: { touched, error, warning },
}) => (
  <div className="select-field">
    <select
      {...input}
      value={String(input.value)}
      className={`${className} ${touched && error ? 'is-invalid' : ''}`}
      disabled={disabled}>
      {children}
    </select>
    {touched &&
      ((error && <span className="redux-form-error">{error}</span>) ||
        (warning && <span className="redux-form-warning">{warning}</span>))}
  </div>
);

export const RadioInput = ({ input, className, title, fieldValue }) => (
  <div className="custom-control custom-radio custom-control-inline">
    <input
      autoComplete="none"
      {...input}
      className={`${className ?? 'custom-control-input'}`}
      type="radio"
      id={title}
      value={fieldValue}
      checked={input.value === fieldValue}
    />
    <label className="custom-control-label" htmlFor={title}>
      {title}
    </label>
  </div>
);

export const SwitchToggle = ({
  input,
  labelName,
  fullWidthLabel,
  meta: { touched, error, warning },
}) => (
  <>
    <div className="switch-container w-100">
      <span className={`label-default ${fullWidthLabel ? 'label-text' : ''}`}>
        {labelName}
      </span>
      <label className="switch">
        <input
          autoComplete="none"
          {...input}
          checked={input.value}
          type="checkbox"
        />
        <span className="slider round" />
      </label>
    </div>
    {touched && (
      <div className="switch-box-status w-100">
        {(error && (
          <span className="redux-form-error">
            <i className="fas fa-times-circle mr-1" />
            {error}
          </span>
        )) ||
          (warning && (
            <span className="redux-form-warning">
              <i className="fas fa-exclamation-circle mr-1" />
              {warning}
            </span>
          ))}
      </div>
    )}
  </>
);
