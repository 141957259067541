import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { menus } from '../../../constants/data';
import { NavLink, useNavigate } from 'react-router-dom';

const MenuBar = props => {
  return (
    <div className="menu-bar">
      {menus
        .filter(i => i.id !== '4')
        .map(menu => (
          <NavLink
            key={menu.id}
            to={menu.url}
            className={({ isActive }) => {
              return isActive && menu.url !== '#'
                ? 'menu-item active'
                : 'menu-item';
            }}
            onClick={() => {}}>
            <span className={`icon ${menu.icon}`}></span>
            <span>{menu.name}</span>
          </NavLink>
        ))}
    </div>
  );
};

MenuBar.propTypes = {};

export default MenuBar;
