import { updateState } from '../../lib/utils';
import { engineActions as types } from '../actionTypes';

const defaultState = {
  loading: false,
  error: null,
  testData: {},
  testJson: {},
  studentTestData: {},
  studentResponse: [],
  tracks: [],
  sections: [],
  questions: [],
  questionGrps: [],
  activeSection: {},
  activeQuestion: {},
  totalQuestions: 0,
  totalSections: 0,
  totalQuestionGrps: 0,
  currentPsgPos: 0,
  isFirstPsg: false,
  isLastPsg: false,
  launching: false,
  testCompleted: false,
  prevTime: 0,
  trackNumber: 0,
  studentId: null,
  testId: null,
  testname: null,
  companyData: {},
  totalDuration: null,
  instruction: null,
  instructions: [],
  showInstruction: true,
  userData: {},
  testTemplate: null,
  isAdministrator: 0,
  ending: false,
  testDownloading: false,
  testDownloadError: null,
  testDownloadingRef: null,
  syncing: false,
  syncId: '',
  attempt: null,
  selectedOptionId: null,
  isCorrect: null,
  marked: null,
  submitting: false
};

export default (state = defaultState, actions) => {
  switch (actions.type) {
    case types.SET_TEST_DATA:
      return updateState(state, { ...actions.payload });

    case types.SAVE_TRACKS:
      return updateState(state, { ...actions.payload });

    case types.LAUNCH_TEST:
      return updateState(state, { ...actions.payload });

    case types.SYNC_SUCCESS:
      return updateState(state, { ...actions.payload });
    case types.SYNC_FAILED:
      return updateState(state, { synError: actions.error.synError });
    case types.SYNC_NOITEMS:
      return updateState(state, { ...actions.payload });

    case types.SAVE_ANSWER:
      return updateState(state, { ...actions.payload });

    case types.END_TEST_START:
      return updateState(state, { ...actions.payload });
    case types.END_TEST_SUCCESS:
      return updateState(state, { ...actions.payload });
    case types.END_TEST_FAILED:
      return updateState(state, { ...actions.payload });

    case types.TEST_COMPLETED:
      return updateState(state, { ...actions.payload });

    case types.TEST_DOWNLOAD:
      return updateState(state, { ...actions.payload });

    case types.SYNC_RECORDS:
      return updateState(state, { ...actions.payload });

    case types.START_TEST:
      return updateState(state, { ...actions.payload });

    case types.SUBMIT_EVALUATION:
      return updateState(state, { ...actions.payload });

    default:
      return state;
  }
};
