import { useState, useEffect } from 'react';

export const checkScreenWidth = () => {
  const width = window.screen.width;
  return width;
};

const useScreenProps = () => {
  const [screenProps, setScreenProps] = useState({});

  useEffect(() => {
      const width = checkScreenWidth();
      setScreenProps({
        width,
        isMobileView: width <= 768,
      });
  }, []);

  return screenProps;
};

export default useScreenProps;
