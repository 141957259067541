import { timerActions as types } from '../actionTypes';
import * as engineActions from './action-engine';

export const setTimer = seconds => ({
  type: types.SET_TIMER,
  seconds,
});

export const clearTimer = () => (dispatch, getState) => {
  const { countDownTimer } = getState().timer;
  if (countDownTimer) {
    clearInterval(countDownTimer);
  }
  dispatch({
    type: types.SET_COUNTDOWN_TIMER,
    countDownTimer: null,
  });
};

export const stopTimer = () => dispatch => {
  dispatch(clearTimer());
  dispatch({ type: types.STOP_TIMER });

  dispatch(engineActions.handleEndTest());
};

export const createTimer = currentTime => dispatch => {
  let timeLeft = currentTime;
  const countDownTimer = setInterval(() => {
    timeLeft--;

    dispatch(setTimer(timeLeft));
    if (timeLeft <= 0) {
      clearInterval(countDownTimer);
      dispatch(stopTimer());
    }
  }, 1000);

  dispatch({
    type: types.SET_COUNTDOWN_TIMER,
    countDownTimer,
  });
};

export const startTimer = seconds => dispatch => {
  dispatch(clearTimer());
  dispatch({
    type: types.START_TIMER,
    seconds,
  });
  dispatch(createTimer(seconds));
};
