import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './Header';
import { TestEngineRootContext } from '../../../contexts';
import { CustomModal } from '../../domain';
import QuitTest from '../QuitTest';
import { connect } from 'react-redux';
import { handleEndTest } from '../../../redux/actions/action-engine';
import useScreenProps from '../../../hooks/useScreenProps';

const Layout = props => {
  const [rootParams, setEngineRootParams] = useState({});
  const [resizedHeight, setResizedHeight] = useState({});
  const { isMobileView } = useScreenProps();

  useEffect(() => {
    window.addEventListener('resize', () => {
      const newHeight = window.innerHeight;
      setResizedHeight(newHeight)
    });
  }, [])

  function updateHeight() {
    const height = resizedHeight;

    let contentHeight = height;
    if (isMobileView) {
      contentHeight = height - 65;
    } else {
      contentHeight = height - 105;
    }

    document.getElementById('engine-content').style.height =
      contentHeight + 'px';
    document.getElementById('engine-content').style.minHeight =
      contentHeight + 'px';
  }

  useEffect(() => {
    updateHeight();
  }, [isMobileView, resizedHeight]);

  return (
    <TestEngineRootContext.Provider
      value={{
        ...rootParams,
        setEngineRootParams,
      }}>
      <div className="container-fluid p-0">
        <div className="engine-layout-body g-0">
          <React.Fragment>
            <main className="engine-layout-container">
              <Header />
              <div id="engine-content" className="content">
                <Outlet />
              </div>
              <CustomModal
                centered
                onClose={() =>
                  setEngineRootParams(p => ({ ...p, showQuitModal: false }))
                }
                show={rootParams.showQuitModal}
                header={false}
                footer={false}>
                <QuitTest
                  onSuccess={props.handleEndTest}
                  loading={props.ending}
                  onClose={() =>
                    setEngineRootParams(p => ({ ...p, showQuitModal: false }))
                  }
                />
              </CustomModal>
            </main>
          </React.Fragment>
        </div>
      </div>
    </TestEngineRootContext.Provider>
  );
};

Layout.propTypes = {
  handleEndTest: PropTypes.func.isRequired,
};

const mapStateToProps = ({ engine }) => ({
  ending: engine.ending,
});

const mapDispatchToProps = dispatch => ({
  handleEndTest: () => dispatch(handleEndTest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
