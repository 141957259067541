import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderHtmlWithBlobs from '../RenderHtmlWithBlob';

const Mcq = ({ question, handleSelectValue, studentResponse }) => {
  useEffect(() => {
    if (typeof window?.MathJax !== 'undefined') {
      window.MathJax.typeset();
    }
  }, [question?.ItemID]);

  const studentAns = useMemo(
    () =>
      studentResponse.find(
        t =>
          t.sectionid === question.QuestionPaperSectionID &&
          t.itemId === question.ItemID,
      ),
    [question.ItemID, studentResponse],
  );

  return (
    <div
      className="type-mcq-wrapper"
      key={question.ItemID}>
      <div className="qn-question">
        <RenderHtmlWithBlobs htmlString={question.Items} />
      </div>
      <div className="qn-options">
        {question.ItemOptionResponse.map(optionItem => (
          <div
            className="form-check mb-2"
            key={optionItem.ItemOptionID}>
            <input
              className="form-check-input"
              type="radio"
              name={optionItem.ItemID}
              id={optionItem.ItemOptionID}
              onChange={e => {
                handleSelectValue(e, question);
              }}
              value={optionItem.ItemOptionID}
              checked={optionItem.ItemOptionID === studentAns?.selectedOptionId}
            />
            <label htmlFor={optionItem.ItemOptionID}>
              <RenderHtmlWithBlobs
                className="form-check-label"
                htmlString={optionItem.Options}
              />
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

Mcq.propTypes = {
  question: PropTypes.object.isRequired,
  handleSelectValue: PropTypes.func.isRequired,
  studentResponse: PropTypes.array.isRequired,
};

const mapStateToProps = ({ engine }) => ({
  studentResponse: engine.studentResponse,
});

export default connect(mapStateToProps)(Mcq);
