import React from 'react';

import PropTypes from 'prop-types';

import Button from '../domain/Button';

const QuitTest = ({ onSuccess, loading, onClose }) => {
  return (
    <div className="quit-test">
      <div className="alert-msg">
        <p className="title">{'Finish the test?'}</p>
        <p className="description">
          {'Are you sure you want to quit the test?'}
        </p>
      </div>
      <div className="quit-btns">
        <Button
          className="btn btn-dark rounded-0 flex-1"
          role="presentation"
          disabled={loading}
          onClick={onClose}>
          Go to test
        </Button>
        <Button
          className="btn btn-primary rounded-0"
          role="presentation"
          onClick={onSuccess}
          loading={loading}
          disabled={loading}
          loadingText={'Please wait...'}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

QuitTest.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default QuitTest;
