export const menus = [
  {
    id: '1',
    name: 'Tests',
    url: '',
    parentId: '0',
    subMenus: [],
    icon: 'far fa-pencil-ruler',
  },
  {
    id: '2',
    name: 'Test Launch',
    url: 'test-launch',
    parentId: '0',
    subMenus: [],
    icon: 'far fa-rocket',
  },
  {
    id: '3',
    name: 'Health Checkup',
    url: 'health-check',
    parentId: '0',
    subMenus: [],
    icon: 'far fa-laptop-medical',
  },
  // {
  //   id: '4',
  //   name: 'Logout',
  //   url: 'logout',
  //   isLogout: true,
  //   parentId: '0',
  //   subMenus: [],
  //   icon: 'far fa-power-off',
  // },
];

export const TOAST_TEST_DOWNLOADING = 'TEST_DOWNLOADING';
export const TOAST_TEST_DOWNLOADED = 'TEST_DOWNLOADED';