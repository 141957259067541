import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { validateForgotForm } from '../lib/formValidations';
import { AlertBox, Button } from '../components/domain';
import {
  InputField,
} from '../components/domain/ReduxFormComponents';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ResetPassword from '../pages/ResetPassword';

const Form = ({ errorMsg, ...props }) => {
  const navigate = useNavigate();
  const [showResetForm, setShowResetForm] = useState(false);

  useEffect(() => {
    if (props.successMsg) {
      setShowResetForm(true);
    }
  });
  return (
    <>
    {showResetForm === true ? (
      <ResetPassword />
    ) : (
    <form onSubmit={props.handleSubmit}>
      <div className="mb-3">
        <h6 className='d-block text-center mb-4'>Forgot Password</h6>
        {errorMsg && <AlertBox show message={errorMsg} type="danger" />}
        <Field
          type="loginId"
          name="loginId"
          className="form-control"
          component={InputField}
          placeholder="Enter your login id"
        />
      </div>
      <div className="d-grid gap-2 ">
        <Button
          type="submit"
          className="btn btn-success rounded-5 login-btn mt-2"
          loading={props.loading}
          loadingText="Generating..."
          disabled={props.loading}>
          Generate OTP
        </Button>
      </div>
      <div>
        <p className='text-primary cursor-pointer d-flex justify-content-center mt-3' onClick={()=> navigate('/')}>Login</p>
      </div>
    </form>
    )
  }
  </>
  );
};

const ForgotForm = reduxForm({
  form: 'ForgotPassword',
  validate: validateForgotForm,
})(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])])
};

const mapState = ({ auth }) => ({
  loggingIn: auth.loggingIn,
  errorMsg: auth.forgotFailed,
  successMsg: auth.forgotSuccess,
  loading: auth.forgotLoading,
});

export default connect(mapState)(ForgotForm);
